import "../../../assets/css/TimeRange/timeRangePopup.css";
import Button from "../../Reusables/Button/Button";
import Close from "../../../assets/icons/close.png";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../../../Context/Context";
import DatePicker from "../../Reusables/DatePicker/DatePicker";
import TimePicker from "../../Reusables/TimePicker/TimePicker";
import moment from "moment";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../util/helper/helperFunctions";
import AlertMessage from "../../Reusables/AlertMessage/AlertMessage";

const TimeRangePopup = () => {
  //Context
  const {
    timeRangePopup,
    setTimeRangePopup,
    setNavQualityHeatmapIsEnabled,
    setNavHeatmapTimeFrames,
    setEnableNavHeatmap,
  } = useContext(AppContext);
  const { timeMachineTimeFrames, setTimeMachineTimeFrames } =
    useContext(AppContext);
  const { heatmapTimeFrames, setHeatmapTimeFrames } = useContext(AppContext);
  const { timeMachineModeIsEnabled, setTimeMachineModeIsEnabled } =
    useContext(AppContext);
  const { isTimeMachineDataAvailable, setIsTimeMachineDataAvailable } =
    useContext(AppContext);
  const { isHeatmapModeEnabled, setIsHeatmapModeEnabled } =
    useContext(AppContext);
  const { currentPageCount, setCurrentPageCount } = useContext(AppContext);
  const { timeMachineLoader, setTimeMachineLoader } = useContext(AppContext);
  const { enableHeatmap, setEnableHeatmap } = useContext(AppContext);
  const { convertedHeatmapData, setConvertedHeatmapData } =
    useContext(AppContext);
  const { timeMachineV2ModeIsEnabled, setTimeMachineV2ModeIsEnabled } =
    useContext(AppContext);

  //Hooks
  const [timeFrame, setTimeFrame] = useState({
    fromDate: "",
    toDate: "",
    fromTime: "",
    toTime: "",
  });
  const [timeFrameValidation, setTimeFrameValidation] = useState({
    fromDate: true,
    toDate: true,
    fromTime: true,
    toTime: true,
  });
  const [isTimeFrameValid, setIsTimeFrameValid] = useState(false);
  const [alertMessageType, setAlertMessageType] = useState(null);

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("time-range-wrapper-id")) {
      closeTimeRangePopup(false);
    }
  });

  useEffect(() => {
    if (timeMachineTimeFrames?.fromDate !== "") {
      setTimeFrame(timeMachineTimeFrames);
    }
    clearTimeFrame();
  }, []);

  const [timeFrameSelected, setTimeFrameSelected] = useState("");

  const enterTimeMachineMode = (type) => {
    switch (type) {
      case "v1":
        setTimeMachineModeIsEnabled(true);
      case "v2":
        setTimeMachineV2ModeIsEnabled(true);
    }
    localStorage.setItem("isTimeMachineModeEnabled", "true");
  };

  const enterHeatmapMode = () => {
    setIsHeatmapModeEnabled(true);
  };

  //Nav Quality Heatmap
  const enterNavHeatmapMode = () => {
    setNavQualityHeatmapIsEnabled(true);
  };

  const clearTimeFrame = () => {
    setTimeFrame({
      fromDate: "",
      toDate: "",
      fromTime: "",
      toTime: "",
    });
    setTimeFrameSelected("");
    setMinAndMaxDateRange();
    setTimeRangeMinimumDate(timeFrame.fromDate);
    setTimeRangeMaximumDate(timeFrame.toDate);
    setTimeFrame((data) => ({ ...data, fromTime: "", toTime: "" }));
    setIsTimeFrameValid(true);
  };

  useEffect(() => {
    if (convertedHeatmapData == null) {
      clearTimeFrame();
    }
  }, [convertedHeatmapData]);

  const resetAlertMessage = () => {
    setTimeout(() => {
      // setIsTimeFrameValid(true);
      setAlertMessageType(null);
    }, 5000);
  };

  const applyTimeFrame = () => {
    let modalType = JSON.parse(localStorage.getItem("timeRangeType"));
    setValidateTimeFrame();
    switch (modalType) {
      case "timeMachineV2":
        if (validateTimeFrames()) {
          setTimeMachineTimeFrames(timeFrame);
          enterTimeMachineMode("v2");
          closeTimeRangePopup();
          setIsTimeFrameValid(true);
        } else {
          setTimeFrameValidation((timeFrameValidation) => ({
            ...timeFrameValidation,
            fromTime: false,
          }));
          setIsTimeFrameValid(false);
          setAlertMessageType("warning");
          resetAlertMessage();
        }
        break;
      case "timeMachine":
        if (validateTimeFrames()) {
          setTimeMachineTimeFrames(timeFrame);
          setTimeRangePopup(!timeRangePopup);
          enterTimeMachineMode();
          setIsTimeMachineDataAvailable(true);
          localStorage.setItem(
            "timeMachineDataAvailable",
            JSON.stringify(true)
          );
          setLocalStorageItem("seekPosition", 0);
          setLocalStorageItem("totalSeekPosition", 1);
          setLocalStorageItem("timeMachineDataAvailable", true);
          setLocalStorageItem("currentPageTimeMachineData", {
            pageSize: 0,
            data: ["startData"],
          });
          setCurrentPageCount(1);
          setLocalStorageItem("groupDataCount", 0);
          setLocalStorageItem("groupDataCurrentCount", 0);
          setTimeMachineLoader(true);
          closeTimeRangePopup();
        } else {
          setTimeFrameValidation((timeFrameValidation) => ({
            ...timeFrameValidation,
            fromTime: false,
          }));
          setIsTimeFrameValid(false);
          setAlertMessageType("warning");
          resetAlertMessage();
        }
        break;
      case "heatmap":
        if (validateTimeFrames()) {
          setHeatmapTimeFrames(timeFrame);
          setTimeRangePopup(!timeRangePopup);
          setLocalStorageItem("heatmapTotalPageCount", 0);
          setLocalStorageItem("heatmapCurrentPageCount", 1);
          setEnableHeatmap(true);
          enterHeatmapMode();
          closeTimeRangePopup();
        } else {
          setTimeFrameValidation((timeFrameValidation) => ({
            ...timeFrameValidation,
            fromTime: false,
          }));
          setIsTimeFrameValid(false);
          setAlertMessageType("warning");
          resetAlertMessage();
        }
        break;
      case "navHeatmap":
        if (validateTimeFrames()) {
          setNavHeatmapTimeFrames(timeFrame);
          setTimeRangePopup(!timeRangePopup);
          setEnableNavHeatmap(true);
          enterNavHeatmapMode();
          closeTimeRangePopup();
        } else {
          setTimeFrameValidation((timeFrameValidation) => ({
            ...timeFrameValidation,
            fromTime: false,
          }));
          setIsTimeFrameValid(false);
          setAlertMessageType("warning");
          resetAlertMessage();
        }
        break;
    }
  };

  const validateTimeFrames = () => {
    if (
      timeFrame.fromDate == undefined ||
      timeFrame.toDate == undefined ||
      timeFrame.fromTime == undefined ||
      timeFrame.toTime == undefined ||
      timeFrame.fromDate == "" ||
      timeFrame.toDate == "" ||
      timeFrame.fromTime == "" ||
      timeFrame.toTime == ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const setValidateTimeFrame = () => {
    if (timeFrame.fromDate == "" || timeFrame.fromDate == undefined) {
      setTimeFrameValidation((validation) => ({
        ...validation,
        fromDate: false,
      }));
    } else {
      setTimeFrameValidation((validation) => ({
        ...validation,
        fromDate: true,
      }));
    }
    if (timeFrame.toDate == "" || timeFrame.toDate == undefined) {
      setTimeFrameValidation((validation) => ({
        ...validation,
        toDate: false,
      }));
    } else {
      setTimeFrameValidation((validation) => ({ ...validation, toDate: true }));
    }
    if (timeFrame.fromTime == "" || timeFrame.fromTime == undefined) {
      setTimeFrameValidation((validation) => ({
        ...validation,
        fromTime: false,
      }));
    } else {
      setTimeFrameValidation((validation) => ({
        ...validation,
        fromTime: true,
      }));
    }
    if (timeFrame.toTime == "" || timeFrame.toTime == undefined) {
      setTimeFrameValidation((validation) => ({
        ...validation,
        toTime: false,
      }));
    } else {
      setTimeFrameValidation((validation) => ({ ...validation, toTime: true }));
    }
  };

  const getTodayDate = (date) => {
    return new Date(date).toISOString().split("T")[0];
  };

  const getPastDate = (date, pastDateCount) => {
    let todayDate = new Date(date);
    return new Date(todayDate.setDate(todayDate.getDate() + 1 - pastDateCount))
      .toISOString()
      .split("T")[0];
  };

  const getEarlierMin = (date, timeEarlier) => {
    let newDate = moment().subtract(timeEarlier, "m")._d;
    let pastTime = String(newDate).split(" ")[4];
    let pastHour = pastTime.split(":")[0];
    let pastMinute = pastTime.split(":")[1];
    return `${pastHour}:${pastMinute}`;
  };

  const getEarlierHr = (date, timeEarlier) => {
    let newDate = moment().subtract(timeEarlier, "h")._d;
    let pastTime = String(newDate).split(" ")[4];
    let pastHour = pastTime.split(":")[0];
    let pastMinute = pastTime.split(":")[1];
    return `${pastHour}:${pastMinute}`;
  };

  const getCurrentTime = (date) => {
    let currentHour = String(date.getHours()).padStart(2, "0");
    let currentMinute = String(date.getMinutes()).padStart(2, "0");
    return `${currentHour}:${currentMinute}`;
  };

  const set15MinsTimeFrame = () => {
    let date = new Date();
    let dateToday = getTodayDate(date);

    let timeToday = getCurrentTime(date);
    setTimeFrame((date) => ({
      ...date,
      fromDate: dateToday,
      toTime: timeToday,
    }));

    let earlierTime = getEarlierMin(date, 15);
    setTimeFrame((date) => ({
      ...date,
      toDate: dateToday,
      fromTime: earlierTime,
    }));
    setTimeFrameSelected("15");
  };

  const set30MinsTimeFrame = () => {
    let date = new Date();
    let dateToday = getTodayDate(date);

    // let timeToday = getCurrentTime(date);
    let altered15MinsFromCurrentTime = getEarlierMin(date, 15);
    setTimeFrame((date) => ({
      ...date,
      fromDate: dateToday,
      toTime: altered15MinsFromCurrentTime,
    }));

    let earlierTime = getEarlierMin(date, 45);
    setTimeFrame((date) => ({
      ...date,
      toDate: dateToday,
      fromTime: earlierTime,
    }));
    setTimeFrameSelected("30");
    setIsTimeFrameValid(false);
    setAlertMessageType("altered");
    resetAlertMessage();
  };
  const set1hrTimeFrame = () => {
    let date = new Date();
    let dateToday = getTodayDate(date);

    // let timeToday = getCurrentTime(date);
    let altered15MinsFromCurrentTime = getEarlierMin(date, 15);

    setTimeFrame((date) => ({
      ...date,
      fromDate: dateToday,
      toTime: altered15MinsFromCurrentTime,
    }));

    let earlierTime = getEarlierHr(date, 1.25);
    setTimeFrame((date) => ({
      ...date,
      toDate: dateToday,
      fromTime: earlierTime,
    }));
    setTimeFrameSelected("1");

    setIsTimeFrameValid(false);
    setAlertMessageType("altered");
    resetAlertMessage();
  };

  const set3hrTimeFrame = () => {
    let date = new Date();
    let dateToday = getTodayDate(date);

    // let timeToday = getCurrentTime(date);
    let altered15MinsFromCurrentTime = getEarlierMin(date, 15);
    setTimeFrame((date) => ({
      ...date,
      fromDate: dateToday,
      toTime: altered15MinsFromCurrentTime,
    }));

    let earlierTime = getEarlierHr(date, 3.25);
    setTimeFrame((date) => ({
      ...date,
      toDate: dateToday,
      fromTime: earlierTime,
    }));
    setTimeFrameSelected("3");

    setIsTimeFrameValid(false);
    setAlertMessageType("altered");
    resetAlertMessage();
  };

  const setTodayTimeFrame = () => {
    let date = new Date();

    let dateToday = getTodayDate(date);
    // let timeToday = getCurrentTime(date);
    let altered15MinsFromCurrentTime = getEarlierMin(date, 15);

    setTimeFrame((data) => ({
      ...data,
      fromDate: dateToday,
      fromTime: "00:00",
      toTime: altered15MinsFromCurrentTime,
      toDate: dateToday,
    }));
    setTimeFrameSelected("today");

    setIsTimeFrameValid(true);
    setAlertMessageType("altered");
    resetAlertMessage();
  };

  const [timeRangeMinimumDate, setTimeRangeMinimumDate] = useState("");
  const [timeRangeMaximumDate, setTimeRangeMaximumDate] = useState("");

  useEffect(() => {
    setMinAndMaxDateRange();
  }, []);

  const setMinAndMaxDateRange = () => {
    let date = new Date();
    let minPastDate = getPastDate(date, 5);
    let maxPastDate = getTodayDate(date);
    timeFrame.fromDate = minPastDate;
    timeFrame.toDate = maxPastDate;
    setTimeFrame(timeFrame);
  };

  useEffect(() => {
    setMinAndMaxDateRange();
  }, []);

  const closeTimeRangePopup = () => {
    setTimeRangePopup(!timeRangePopup);
  };

  const getCurrentFeature = () =>
    getLocalStorageItem("timeRangeType") == "navHeatmap";

  useEffect(() => {
    if (timeFrame.fromDate !== "") {
      setTimeRangeMinimumDate(timeFrame.fromDate);
    }
    if (timeFrame.toDate !== "") {
      setTimeRangeMaximumDate(timeFrame.toDate);
    }

    if (
      timeFrame.fromDate &&
      timeFrame.toDate &&
      timeFrame.fromTime &&
      timeFrame.toTime
    ) {
      if (getCurrentFeature()) {
        let fromDataObj = moment(
          `${timeFrame.fromDate}T${timeFrame.fromTime}:00`
        );
        let toDataObj = moment(`${timeFrame.toDate}T${timeFrame.toTime}:00`);

        // const hoursDifference = toDataObj.diff(fromDataObj, "hours");
        const minDifference = toDataObj.diff(fromDataObj, "minutes");
        if (minDifference <= 360) {
          setIsTimeFrameValid(false);
        } else if (minDifference === 0 || minDifference > 360) {
          setAlertMessageType("restricted");
          resetAlertMessage();
          setIsTimeFrameValid(true);
        }
      } else {
        setIsTimeFrameValid(false);
      }
    }
  }, [
    timeFrame.fromDate,
    timeFrame.toDate,
    timeFrame.fromTime,
    timeFrame.toTime,
  ]);

  document.addEventListener("click", (e) => {
    if (e.target == document.getElementById("time-range-wrapper-id")) {
      setTimeRangePopup(!timeRangePopup);
    }
  });

  const renderAlertMessage = useMemo(() => {
    if (alertMessageType !== null) {
      let alertProps = {
        heading: "",
        description: "",
        type: "",
      };
      switch (alertMessageType) {
        case "warning":
          alertProps.heading = "Invalid Time Range";
          alertProps.description = "Time Range must be greater than 15 mins";
          alertProps.type = "warning";
          break;
        case "altered":
          alertProps.heading = "Time Range Altered";
          alertProps.description =
            "Time Range is altered since last 15 mins data is not available";
          alertProps.type = "warning";
          break;
        case "restricted":
          alertProps.heading = "Time Range Invalid";
          alertProps.description = "Time Range is only available for 6 hours";
          alertProps.type = "warning";
          break;
      }
      return (
        <AlertMessage
          heading={alertProps.heading}
          description={alertProps.description}
          type={alertProps.type}
        />
      );
    }
  }, [alertMessageType]);

  return (
    <>
      {timeRangePopup ? (
        <div id="time-range-wrapper-id" className="time-range-wrapper">
          <div className="time-range-container">
            <div className="time-range-top-section">
              <p>Time Range</p>
              <img
                onClick={() => closeTimeRangePopup()}
                className="time-range-popup-close-btn"
                src={Close}
              />
            </div>
            <div className="time-range-middle-section">
              <div className="picker-section">
                <div className="time-range-left-panel">
                  From
                  <div className="time-range-pickers">
                    <DatePicker
                      validation={timeFrameValidation.fromDate}
                      date={timeFrame.fromDate}
                      getDate={setTimeFrame}
                      name="fromDate"
                      min={timeRangeMinimumDate}
                      max={timeRangeMaximumDate}
                    />
                    <TimePicker
                      validation={timeFrameValidation.fromTime}
                      time={timeFrame.fromTime}
                      getTime={setTimeFrame}
                      name="fromTime"
                    />
                  </div>
                </div>
                <div className="time-range-right-panel">
                  To
                  <div className="time-range-pickers">
                    <DatePicker
                      validation={timeFrameValidation.toDate}
                      date={timeFrame.toDate}
                      getDate={setTimeFrame}
                      name="toDate"
                      min={timeRangeMinimumDate}
                      max={timeRangeMaximumDate}
                    />
                    <TimePicker
                      validation={timeFrameValidation.toTime}
                      time={timeFrame.toTime}
                      getTime={setTimeFrame}
                      name="toTime"
                    />
                  </div>
                </div>
              </div>
              <div className="time-range-info">
                <p>Please enter the time as per your system time format.</p>
              </div>
              <div className="time-frame-section">
                {/* <Button
                  onClick={() => set15MinsTimeFrame()}
                  placeholder="Last 15 mins"
                  btnClass={`time-frame-btn ${
                    timeFrameSelected == "15" ? "time-frame-btn-selected" : ""
                  }`}
                ></Button> */}
                <Button
                  onClick={() => set30MinsTimeFrame()}
                  placeholder="Last 30 mins"
                  btnClass={`time-frame-btn ${
                    timeFrameSelected == "30" ? "time-frame-btn-selected" : ""
                  }`}
                ></Button>
                <Button
                  onClick={() => set1hrTimeFrame()}
                  placeholder="Last 1 hr"
                  btnClass={`time-frame-btn ${
                    timeFrameSelected == "1" ? "time-frame-btn-selected" : ""
                  }`}
                ></Button>
                <Button
                  onClick={() => set3hrTimeFrame()}
                  placeholder="Last 3 hr"
                  btnClass={`time-frame-btn ${
                    timeFrameSelected == "3" ? "time-frame-btn-selected" : ""
                  }`}
                ></Button>
                <Button
                  onClick={() => setTodayTimeFrame()}
                  placeholder="Today"
                  btnClass={`time-frame-btn ${
                    timeFrameSelected == "today"
                      ? "time-frame-btn-selected"
                      : ""
                  }`}
                ></Button>
              </div>
            </div>
            <div className="time-range-bottom-section">
              <Button
                onClick={() => clearTimeFrame()}
                placeholder="Reset"
                btnClass="time-range-discard-btn time-range-btn"
              ></Button>
              <Button
                onClick={() => applyTimeFrame()}
                placeholder="Apply"
                btnClass={`time-range-confirm-btn time-range-btn ${
                  isTimeFrameValid ? "time-range-invalid" : ""
                }`}
                disabled={isTimeFrameValid}
              ></Button>
            </div>
            {renderAlertMessage}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TimeRangePopup;
