import { useRef } from "react";
import "../../../assets/css/YardView/YardDetails.css";
import closeButton from "../../../assets/icons/close.png";
import { convertTimestampIntoHHMM } from "../../../util/helper/helperFunctions";

const YardDetails = ({ yardData = {}, setIsYardDetailModalOpen }) => {
  const {
    locationName,
    locationStatus,
    trailerStatus,
    xCoord,
    xLength,
    yCoord,
    yLength,
    ...restYardData
  } = yardData;

  const popupRef = useRef(null);

  const modalCloseHandler = () => {
    document.body.classList.remove("prevent-scroll");
    setIsYardDetailModalOpen(false);
  };

  document.addEventListener("click", (e) => {
    if (e.target === popupRef.current) {
      modalCloseHandler();
    }
  });

  const labels = {
    SCAC: restYardData["scac"],
    "Trailer Number": restYardData["trailerNumber"],
    "Check-in Time": convertTimestampIntoHHMM(restYardData["checkInTime"]),
    "Load Type": restYardData["loadType"],
    Status: restYardData["status"],
    "Pickup Appt.": convertTimestampIntoHHMM(restYardData["pickupTime"]),
    "Past Pickup": restYardData["latePickup"],
    "Shipment/Trip Number": restYardData["shipmentNumber"],
    "Distribution/Delivery Number": restYardData["deliveryNumber"],
    Comments: restYardData["comments"],
    Percentage: restYardData["progressPercentage"],
  };

  return (
    <div
      ref={popupRef}
      id="yard-details-popup-wrapper-id"
      className="yard-details-popup-wrapper"
    >
      <div className={`yard-details-popup-container`}>
        <div className="yard-details-popup-top-section">
          <h3>Yard Details ({locationName ? locationName : null})</h3>
          <div className="yard-modal-action-buttons">
            <div
              onClick={() => modalCloseHandler()}
              className="yard-modal-action-button-wrapper"
            >
              <img
                className="yard-modal-action-button"
                src={closeButton}
                alt="close icon"
              />
            </div>
          </div>
        </div>
        <div className="yard-details-popup-bottom-section">
          {Object.keys(labels)?.map((title) => (
            <div className="yard-bottom-item-container">
              <p className="yard-details-title">{title}</p>
              <p className="yard-details-item-value">{labels[title]}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YardDetails;
