import { Checkbox } from "antd";
import { useEffect, useState } from "react";
import "./checklist.css";
import { formatLgvListToCheckList } from "../../../util/helper/navHeatmap/navHeatmapHelper";

const CheckList = ({ options, onChange }) => {
  const [checkListOptions, setCheckListOptions] = useState(options);
  const [checkedList, setCheckedList] = useState([]);
  const indeterminate =
    checkedList.length > 0 && checkedList.length < checkListOptions.length;

  /**
   * Function - To Get the first Option available from the LGV data
   * @param {*} options
   * @returns
   */
  const getFirstOption = (options) => {
    let firstOption = options[0].value;
    return firstOption;
  };

  /**
   * Function - Format Check list options to checked values
   * Input - Array of Object
   * Output - String Array
   */
  const formatCheckListOptionToCheckedValues = (listOptions) => {
    let checkedValues = [];
    listOptions.forEach((item) => {
      if (item) {
        checkedValues.push(item.value);
      }
    });
    return checkedValues;
  };

  const onChangeHandler = (type, checkedValues, ...args) => {
    switch (type) {
      case "all":
        if (args[0].target.checked) {
          let allCheckedValues =
            formatCheckListOptionToCheckedValues(checkListOptions);
          setCheckedList([...new Set(allCheckedValues)]);
          onChange([...new Set(allCheckedValues)]);
        } else {
          setCheckedList([getFirstOption(options)]);
          onChange([getFirstOption(options)]);
        }
        break;
      case "single":
        setCheckedList([...new Set(checkedValues)]);
        onChange([...new Set(checkedValues)]);
        break;
    }
  };

  useEffect(() => {
    setCheckedList([getFirstOption(options)]);
  }, []);

  return (
    <div className="check-list">
      {checkListOptions && checkListOptions.length !== 0 && (
        <>
          <Checkbox
            className="select-all-btn"
            indeterminate={indeterminate}
            onChange={(e) => onChangeHandler("all", null, e)}
            checked={checkedList.length === checkListOptions.length}
          >
            All
          </Checkbox>
          <Checkbox.Group
            options={checkListOptions}
            value={checkedList}
            onChange={(checkedValues) =>
              onChangeHandler("single", checkedValues)
            }
          />
        </>
      )}
    </div>
  );
};

export default CheckList;
