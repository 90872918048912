export const lgvIconStyles = `
    display:flex;
    background-color: #2b2b2b;
    width:18px;
    height:18px;
    border:1px solid #ffffff;
    border-radius:2px;
    font-size: 12px;
    color:white;
    display:flex;
    justify-content:center;
    align-items:center;
    font-family: 'Poppins' !important;
    z-index: 1008 !important;
`;

export const userDataObject = {
  businessPhones: [],
  displayName: "",
  givenName: "",
  jobTitle: null,
  mail: "",
  mobilePhone: null,
  officeLocation: "",
  preferredLanguage: null,
  surname: "",
  userPrincipalName: "",
  id: "",
};
export const heatmapHorizontalCount = 25;
export const heatmapVerticalCount = 57;

export const plantCarLatSize = 27;
export const plantCarLngSize = 27;

export const heatMapGridX = -1750.3403361344538;
export const heatMapGridY = 538.1567444620252;

export const navHeatmapMaxRetry = 10;

export const defaultMarkerData = {
  vehicleNumber: "1",
  status: "null",
  source: "null",
  destination: "null",
  segment: "null",
  currentPos: { lat: 1, lng: 1 },
  duration: "1",
  alarmName: "null",
  sku: "1",
  lpn: "1",
};

export const noDataDockDetails = {
  DOOR: "No Data Found",
  STATUS: "No Data Found",
  TYPE: "No Data Found",
  SHIPMENT: "No Data Found",
  LOADTIME: 0,
  ALLOCATED: 0,
  RELEASED: 0,
  ACTIVE: 0,
  REMAINPALL: 0,
  CURRENT_DATE_TIME: "No Data Found",
  PROCESSTIME: 0,
  PROGRESS: 0,
};

export const getColorPallets = () => {
  return [
    {
      name: "LGV LEGENDS",
      colors: {
        Available: "#008000",
        Loaded: "#0000FF",
        "In Operation/traffic": "#D96E0B",
        Deadlock: "#EF1FDA",
        Lost: "#B9B9B9",
        Removed: "#000000",
        "In Alarm": "#EE3232",
        "In Manual": "#800000",
        Charging: "#83C4D9",
      },
    },
    {
      name: "PRODUCTION LINE LEGENDS",
      colors: {
        "Line Running": "#4133E1",
        "Pallets waiting for pickup": "#42C721",
        "# LGVs to pickup at line": "#238749",
      },
    },
    {
      name: "TRAILER PALLET LEGENDS",
      colors: {
        Completed: "#42C721",
        Released: "#E1CC0F",
        Allocated: "#B9B9B9",
        "In Transit/Started": "#4133E1",
        "Can Allocate": "#D90000",
        New: "#FF5733",
      },
    },
  ];
};

export const dummyProductionLine = [
  {
    LINE_NAME: "L1",
    STATUS: "Active",
    ITEM: "MMK05L40PDMPCNR02",
    JOB_NUMBER: "L00878253",
    CASES_SCHEDULED: 37536,
    CASES_PRODUCED: 8112,
    CASES_REMAINING: 29424,
    LGV: 0,
    PALLETS: 3,
    STATION_1: 1,
    STATION_2: 0,
    FIP: 0,
    LONGITUDE: 275639,
    LATITUDE: 37930,
  },
  {
    LINE_NAME: "L2",
    STATUS: "Active",
    ITEM: "CLO05L32PDRPCN",
    JOB_NUMBER: "L00879370",
    CASES_SCHEDULED: 10860,
    CASES_PRODUCED: 9420,
    CASES_REMAINING: 1440,
    LGV: 0,
    PALLETS: 3,
    STATION_1: 0,
    STATION_2: 0,
    FIP: 0,
    LONGITUDE: 254550,
    LATITUDE: 37970,
  },
  {
    LINE_NAME: "L3",
    STATUS: "Active",
    ITEM: "GRV05L40PDMPCN",
    JOB_NUMBER: "L00879877",
    CASES_SCHEDULED: 47280,
    CASES_PRODUCED: 37008,
    CASES_REMAINING: 10272,
    LGV: 0,
    PALLETS: 3,
    STATION_1: 0,
    STATION_2: 0,
    FIP: 0,
    LONGITUDE: 149253,
    LATITUDE: 38006,
  },
  {
    LINE_NAME: "L4",
    STATUS: "Active",
    ITEM: "GRV05L40PDMPCN",
    JOB_NUMBER: "L00879877",
    CASES_SCHEDULED: 47280,
    CASES_PRODUCED: 37008,
    CASES_REMAINING: 10272,
    LGV: 0,
    PALLETS: 3,
    STATION_1: 0,
    STATION_2: 0,
    FIP: 0,
    LONGITUDE: 149253,
    LATITUDE: 38006,
  },
];

export const singleBlockData = [
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
  {
    locationName: "I.W.008",
    totalPallets: 124,
    emptyPalletPositions: 0,
    storageDisabled: 0,
    retrievalDisabled: 0,
    storageBlocked: 0,
    retrievalBlocked: 0,
    locationType: "Finished goods double store",
    locationCapacity: 124,
    totalPositions: 62,
    positionTransition: null,
    positionOrientation: null,
    longitude: 438166,
    latitude: 145535,
    positionLength: 40784,
    locationOrientation: "W",
    palletPosition: 22,
    palletRow: 1,
    palletLevel: 1,
    lpn: "00300275412092360451",
    sku: "KRK10L18PAKGPN",
    lot: "B00861204",
    quantity: 56,
    qualityStatus: "AVAILABLE",
    expirationDate: "2026-03-22T00:00:00.000Z",
    productionDate: "2024-03-22T00:00:00.000Z",
  },
];

export const mockLgv = [
  {
    vehicleNumber: "19",
    source: "IB.MASS.HNDOFF.02",
    status: "ActiveFull",
    longitude: "342014",
    latitude: "40199",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "PRT.000000000000000000246486",
    lpn_2: "PRT.000000000000000000246487",
    destination: "C.E.025",
    destinationLongitude: 285421,
    destinationLatitude: 102065,
  },
  {
    vehicleNumber: "42",
    source: "IB.MASS.HNDOFF.02",
    status: "ActiveFull",
    longitude: "259027",
    latitude: "102065",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "PRT.000000000000000000246486",
    lpn_2: "PRT.000000000000000000246487",
    destination: "C.E.025",
    destinationLongitude: 285421,
    destinationLatitude: 102065,
  },
  {
    vehicleNumber: "9",
    source: "IB.MASS.HNDOFF.02",
    status: "Traffic",
    longitude: "499519",
    latitude: "44851",
    duration: 0.57,
    alarmName: "LGV_Yielding_Block\t",
    segment: 1,
    lpn_1: "PRT.000000000000000000246486",
    lpn_2: "PRT.000000000000000000246487",
    destination: "C.E.025",
    destinationLongitude: 285421,
    destinationLatitude: 102065,
  },
  {
    vehicleNumber: "45",
    source: "L3.FNSH.PCKUP.01",
    status: "ActiveFull",
    longitude: "311600",
    latitude: "115168",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "00300275412092424702",
    lpn_2: "00300275412092424719",
    destination: "E.E.013",
    destinationLongitude: 434352,
    destinationLatitude: 71655,
  },
  {
    vehicleNumber: "29",
    source: "L5.FNSH.PCKUP.01",
    status: "ActiveFull",
    longitude: "324535",
    latitude: "115154",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "00500275412095041450",
    lpn_2: "00500275412095041467",
    destination: "H.W.012",
    destinationLongitude: 366314,
    destinationLatitude: 154855,
  },
  {
    vehicleNumber: "34",
    source: "G.W.017",
    status: "ActiveFull",
    longitude: "327109",
    latitude: "125799",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: "STAGE.045",
    destinationLongitude: 247632,
    destinationLatitude: 14696,
  },
  {
    vehicleNumber: "49",
    source: "G.W.017",
    status: "ActiveFull",
    longitude: "247665",
    latitude: "34206",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "00400275412095005240",
    lpn_2: "00400275412095005301",
    destination: "STAGE.045",
    destinationLongitude: 247632,
    destinationLatitude: 14696,
  },
  {
    vehicleNumber: "35",
    source: "G.E.013",
    status: "ActiveFull",
    longitude: "319765",
    latitude: "40168",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: "STAGE.046",
    destinationLongitude: 251519,
    destinationLatitude: 14696,
  },
  {
    vehicleNumber: "7",
    source: "G.E.013",
    status: "ActiveFull",
    longitude: "251629",
    latitude: "25955",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "00300275412092360406",
    lpn_2: "00300275412092360413",
    destination: "STAGE.046",
    destinationLongitude: 251519,
    destinationLatitude: 14696,
  },
  {
    vehicleNumber: "17",
    source: "SSR.045",
    status: "ActiveFull",
    longitude: "267462",
    latitude: "33443",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "00500275412095037996",
    lpn_2: null,
    destination: "STAGE.049",
    destinationLongitude: 267442,
    destinationLatitude: 14696,
  },
  {
    vehicleNumber: "10",
    source: "IB.MASS.HNDOFF.01",
    status: "ActiveFull",
    longitude: "400828",
    latitude: "40161",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "PRT.000000000000000000246476",
    lpn_2: "PRT.000000000000000000246477",
    destination: "B.W.005",
    destinationLongitude: 128510,
    destinationLatitude: 50791,
  },
  {
    vehicleNumber: "12",
    source: "IB.MASS.HNDOFF.01",
    status: "ActiveFull",
    longitude: "174091",
    latitude: "40178",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: "PRT.000000000000000000246476",
    lpn_2: "PRT.000000000000000000246477",
    destination: "B.W.005",
    destinationLongitude: 128510,
    destinationLatitude: 50791,
  },
  {
    vehicleNumber: "14",
    source: "IB.MASS.HNDOFF.01",
    status: "Alarm",
    longitude: "159953",
    latitude: "50796",
    duration: 1.82,
    alarmName: "(A14.00) TimeOut_Tilt_Up\t",
    segment: 1,
    lpn_1: "PRT.000000000000000000246476",
    lpn_2: "PRT.000000000000000000246477",
    destination: "B.W.005",
    destinationLongitude: 128510,
    destinationLatitude: 50791,
  },
  {
    vehicleNumber: "41",
    source: "SSR.034",
    status: "ActiveFull",
    longitude: "283963",
    latitude: "40171",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: "SSR.050",
    destinationLongitude: 252286,
    destinationLatitude: 34047,
  },
  {
    vehicleNumber: "1",
    source: null,
    status: "Idle",
    longitude: "329915",
    latitude: "74621",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "11",
    source: null,
    status: "Idle",
    longitude: "404189",
    latitude: "77743",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "13",
    source: null,
    status: "Removed",
    longitude: null,
    latitude: null,
    duration: 81338.98,
    alarmName: "LGV_Removed",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "15",
    source: null,
    status: "Idle",
    longitude: "406982",
    latitude: "103672",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "16",
    source: null,
    status: "Idle",
    longitude: "329931",
    latitude: "103673",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "18",
    source: null,
    status: "Charging",
    longitude: "328408",
    latitude: "33641",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "53",
    source: null,
    status: "Idle",
    longitude: "481483",
    latitude: "75244",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "6",
    source: null,
    status: "Idle",
    longitude: "240841",
    latitude: "86769",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "8",
    source: null,
    status: "Removed",
    longitude: null,
    latitude: null,
    duration: 1472503.17,
    alarmName: "LGV_Removed",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "47",
    source: null,
    status: "Removed",
    longitude: null,
    latitude: null,
    duration: 73071.52,
    alarmName: "LGV_Removed",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "48",
    source: null,
    status: "Idle",
    longitude: "166379",
    latitude: "102490",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "5",
    source: null,
    status: "Idle",
    longitude: "327064",
    latitude: "89528",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "50",
    source: null,
    status: "Alarm",
    longitude: "300877",
    latitude: "134381",
    duration: 44.77,
    alarmName: "(A2.00) Front PLS\t",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "51",
    source: null,
    status: "Idle",
    longitude: "242963",
    latitude: "37629",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "52",
    source: null,
    status: "Idle",
    longitude: "329308",
    latitude: "116025",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "39",
    source: null,
    status: "Idle",
    longitude: "432964",
    latitude: "115132",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "4",
    source: null,
    status: "Idle",
    longitude: "348530",
    latitude: "119511",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "40",
    source: null,
    status: "Idle",
    longitude: "123289",
    latitude: "116655",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "43",
    source: null,
    status: "Charging",
    longitude: "199071",
    latitude: "123300",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "44",
    source: null,
    status: "Idle",
    longitude: "404182",
    latitude: "56291",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "46",
    source: null,
    status: "Idle",
    longitude: "356027",
    latitude: "115160",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "31",
    source: null,
    status: "Removed",
    longitude: null,
    latitude: null,
    duration: 1190465.42,
    alarmName: "LGV_Removed",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "32",
    source: null,
    status: "Idle",
    longitude: "377744",
    latitude: "119489",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "33",
    source: null,
    status: "Idle",
    longitude: "496845",
    latitude: "47757",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "36",
    source: null,
    status: "Idle",
    longitude: "425490",
    latitude: "119515",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "37",
    source: null,
    status: "Idle",
    longitude: "313751",
    latitude: "36427",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "38",
    source: null,
    status: "Removed",
    longitude: null,
    latitude: null,
    duration: 1064208.4,
    alarmName: "LGV_Removed",
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "25",
    source: null,
    status: "Idle",
    longitude: "404158",
    latitude: "103333",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "26",
    source: null,
    status: "Idle",
    longitude: "478643",
    latitude: "54239",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "27",
    source: null,
    status: "Idle",
    longitude: "203886",
    latitude: "120346",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "28",
    source: null,
    status: "Idle",
    longitude: "407013",
    latitude: "54540",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "3",
    source: null,
    status: "Idle",
    longitude: "406998",
    latitude: "84547",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "30",
    source: null,
    status: "Idle",
    longitude: "457620",
    latitude: "119520",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "2",
    source: null,
    status: "Idle",
    longitude: "145372",
    latitude: "120325",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "20",
    source: null,
    status: "Idle",
    longitude: "301833",
    latitude: "36434",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "21",
    source: null,
    status: "Idle",
    longitude: "465118",
    latitude: "115152",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "22",
    source: null,
    status: "Idle",
    longitude: "328553",
    latitude: "168131",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "23",
    source: null,
    status: "Idle",
    longitude: "385243",
    latitude: "115165",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
  {
    vehicleNumber: "24",
    source: null,
    status: "Idle",
    longitude: "329559",
    latitude: "45761",
    duration: 0,
    alarmName: null,
    segment: 1,
    lpn_1: null,
    lpn_2: null,
    destination: null,
    destinationLongitude: null,
    destinationLatitude: null,
  },
];

export const mockPalletData = {
  location: "mock",
  position: 1,
  row: 2,
  level: 1,
  lpn: "00800275410100072393",
  item: "LESS15AK95V03TRIALS4",
  itemType: "LABL",
  qty: 220,
  lot: null,
  productionDate: "2023-04-03T07:57:00.000Z",
  expirationDate: "2024-10-05T00:00:00.000Z",
};

export const Level1Config = {
  B0101: ["C0101", "C0102", "C0201", "C0202"],
  B0102: ["C0103", "C0104", "C0203", "C0204"],
  B0103: ["C0105", "C0106", "C0205", "C0206"],
  B0104: ["C0107", "C0108", "C0207", "C0208"],
  B0105: ["C0109", "C0110", "C0209", "C0210"],
  B0106: ["C0111", "C0112", "C0211", "C0212"],
  B0107: ["C0113", "C0114", "C0213", "C0214"],
  B0108: ["C0115", "C0116", "C0215", "C0216"],
  B0109: ["C0117", "C0118", "C0217", "C0218"],
  B0110: ["C0119", "C0120", "C0219", "C0220"],
  B0111: ["C0121", "C0122", "C0221", "C0222"],
  B0112: ["C0123", "C0124", "C0223", "C0224"],
  B0113: ["C0125", "C0126", "C0225", "C0226"],
  B0114: ["C0127", "C0128", "C0227", "C0228"],
  B0115: ["C0129", "C0130", "C0229", "C0230"],
  B0116: ["C0131", "C0132", "C0231", "C0232"],
  B0201: ["C0301", "C0302", "C0401", "C0402"],
  B0202: ["C0303", "C0304", "C0403", "C0404"],
  B0203: ["C0305", "C0306", "C0405", "C0406"],
  B0204: ["C0307", "C0308", "C0407", "C0408"],
  B0205: ["C0309", "C0310", "C0409", "C0410"],
  B0206: ["C0311", "C0312", "C0411", "C0412"],
  B0207: ["C0313", "C0314", "C0413", "C0414"],
  B0208: ["C0315", "C0316", "C0415", "C0416"],
  B0209: ["C0317", "C0318", "C0417", "C0418"],
  B0210: ["C0319", "C0320", "C0419", "C0420"],
  B0211: ["C0321", "C0322", "C0421", "C0422"],
  B0212: ["C0323", "C0324", "C0423", "C0424"],
  B0213: ["C0325", "C0326", "C0425", "C0426"],
  B0214: ["C0327", "C0328", "C0427", "C0428"],
  B0215: ["C0329", "C0330", "C0429", "C0430"],
  B0216: ["C0331", "C0332", "C0431", "C0432"],
  B0301: ["C0501", "C0502", "C0601", "C0602"],
  B0302: ["C0503", "C0504", "C0603", "C0604"],
  B0303: ["C0505", "C0506", "C0605", "C0606"],
  B0304: ["C0507", "C0508", "C0607", "C0608"],
  B0305: ["C0509", "C0510", "C0609", "C0610"],
  B0306: ["C0511", "C0512", "C0611", "C0612"],
  B0307: ["C0513", "C0514", "C0613", "C0614"],
  B0308: ["C0515", "C0516", "C0615", "C0616"],
  B0309: ["C0517", "C0518", "C0617", "C0618"],
  B0310: ["C0519", "C0520", "C0619", "C0620"],
  B0311: ["C0521", "C0522", "C0621", "C0622"],
  B0312: ["C0523", "C0524", "C0623", "C0624"],
  B0313: ["C0525", "C0526", "C0625", "C0626"],
  B0314: ["C0527", "C0528", "C0627", "C0628"],
  B0315: ["C0529", "C0530", "C0629", "C0630"],
  B0316: ["C0531", "C0532", "C0631", "C0632"],
  B0401: ["C0701", "C0702", "C0801", "C0802"],
  B0402: ["C0703", "C0704", "C0803", "C0804"],
  B0403: ["C0705", "C0706", "C0805", "C0806"],
  B0404: ["C0707", "C0708", "C0807", "C0808"],
  B0405: ["C0709", "C0710", "C0809", "C0810"],
  B0406: ["C0711", "C0712", "C0811", "C0812"],
  B0407: ["C0713", "C0714", "C0813", "C0814"],
  B0408: ["C0715", "C0716", "C0815", "C0816"],
  B0409: ["C0717", "C0718", "C0817", "C0818"],
  B0410: ["C0719", "C0720", "C0819", "C0820"],
  B0411: ["C0721", "C0722", "C0821", "C0822"],
  B0412: ["C0723", "C0724", "C0823", "C0824"],
  B0413: ["C0725", "C0726", "C0825", "C0826"],
  B0414: ["C0727", "C0728", "C0827", "C0828"],
  B0415: ["C0729", "C0730", "C0829", "C0830"],
  B0416: ["C0731", "C0732", "C0831", "C0832"],
  B0501: ["C0901", "C0902", "C1001", "C1002"],
  B0502: ["C0903", "C0904", "C1003", "C1004"],
  B0503: ["C0905", "C0906", "C1005", "C1006"],
  B0504: ["C0907", "C0908", "C1007", "C1008"],
  B0505: ["C0909", "C0910", "C1009", "C1010"],
  B0506: ["C0911", "C0912", "C1011", "C1012"],
  B0507: ["C0913", "C0914", "C1013", "C1014"],
  B0508: ["C0915", "C0916", "C1015", "C1016"],
  B0509: ["C0917", "C0918", "C1017", "C1018"],
  B0510: ["C0919", "C0920", "C1019", "C1020"],
  B0511: ["C0921", "C0922", "C1021", "C1022"],
  B0512: ["C0923", "C0924", "C1023", "C1024"],
  B0513: ["C0925", "C0926", "C1025", "C1026"],
  B0514: ["C0927", "C0928", "C1027", "C1028"],
  B0515: ["C0929", "C0930", "C1029", "C1030"],
  B0516: ["C0931", "C0932", "C1031", "C1032"],
  B0601: ["C1101", "C1102", "C1201", "C1202"],
  B0602: ["C1103", "C1104", "C1203", "C1204"],
  B0603: ["C1105", "C1106", "C1205", "C1206"],
  B0604: ["C1107", "C1108", "C1207", "C1208"],
  B0605: ["C1109", "C1110", "C1209", "C1210"],
  B0606: ["C1111", "C1112", "C1211", "C1212"],
  B0607: ["C1113", "C1114", "C1213", "C1214"],
  B0608: ["C1115", "C1116", "C1215", "C1216"],
  B0609: ["C1117", "C1118", "C1217", "C1218"],
  B0610: ["C1119", "C1120", "C1219", "C1220"],
  B0611: ["C1121", "C1122", "C1221", "C1222"],
  B0612: ["C1123", "C1124", "C1223", "C1224"],
  B0613: ["C1125", "C1126", "C1225", "C1226"],
  B0614: ["C1127", "C1128", "C1227", "C1228"],
  B0615: ["C1129", "C1130", "C1229", "C1230"],
  B0616: ["C1131", "C1132", "C1231", "C1232"],
  B0701: ["C1301", "C1302", "C1401", "C1402"],
  B0702: ["C1303", "C1304", "C1403", "C1404"],
  B0703: ["C1305", "C1306", "C1405", "C1406"],
  B0704: ["C1307", "C1308", "C1407", "C1408"],
  B0705: ["C1309", "C1310", "C1409", "C1410"],
  B0706: ["C1311", "C1312", "C1411", "C1412"],
  B0707: ["C1313", "C1314", "C1413", "C1414"],
  B0708: ["C1315", "C1316", "C1415", "C1416"],
  B0709: ["C1317", "C1318", "C1417", "C1418"],
  B0710: ["C1319", "C1320", "C1419", "C1420"],
  B0711: ["C1321", "C1322", "C1421", "C1422"],
  B0712: ["C1323", "C1324", "C1423", "C1424"],
  B0713: ["C1325", "C1326", "C1425", "C1426"],
  B0714: ["C1327", "C1328", "C1427", "C1428"],
  B0715: ["C1329", "C1330", "C1429", "C1430"],
  B0716: ["C1331", "C1332", "C1431", "C1432"],
  B0801: ["C1501", "C1502", "C1601", "C1602"],
  B0802: ["C1503", "C1504", "C1603", "C1604"],
  B0803: ["C1505", "C1506", "C1605", "C1606"],
  B0804: ["C1507", "C1508", "C1607", "C1608"],
  B0805: ["C1509", "C1510", "C1609", "C1610"],
  B0806: ["C1511", "C1512", "C1611", "C1612"],
  B0807: ["C1513", "C1514", "C1613", "C1614"],
  B0808: ["C1515", "C1516", "C1615", "C1616"],
  B0809: ["C1517", "C1518", "C1617", "C1618"],
  B0810: ["C1519", "C1520", "C1619", "C1620"],
  B0811: ["C1521", "C1522", "C1621", "C1622"],
  B0812: ["C1523", "C1524", "C1623", "C1624"],
  B0813: ["C1525", "C1526", "C1625", "C1626"],
  B0814: ["C1527", "C1528", "C1627", "C1628"],
  B0815: ["C1529", "C1530", "C1629", "C1630"],
  B0816: ["C1531", "C1532", "C1631", "C1632"],
};

export const Level0Config = {
  A0101: ["B0101", "B0102", "B0201", "B0202"],
  A0102: ["B0103", "B0104", "B0203", "B0204"],
  A0103: ["B0105", "B0106", "B0205", "B0206"],
  A0104: ["B0107", "B0108", "B0207", "B0208"],
  A0105: ["B0109", "B0110", "B0209", "B0210"],
  A0106: ["B0111", "B0112", "B0211", "B0212"],
  A0107: ["B0113", "B0114", "B0213", "B0214"],
  A0108: ["B0115", "B0116", "B0215", "B0216"],
  A0201: ["B0301", "B0302", "B0401", "B0402"],
  A0202: ["B0303", "B0304", "B0403", "B0404"],
  A0203: ["B0305", "B0306", "B0405", "B0406"],
  A0204: ["B0307", "B0308", "B0407", "B0408"],
  A0205: ["B0309", "B0310", "B0409", "B0410"],
  A0206: ["B0311", "B0312", "B0411", "B0412"],
  A0207: ["B0313", "B0314", "B0413", "B0414"],
  A0208: ["B0315", "B0316", "B0415", "B0416"],
  A0301: ["B0501", "B0502", "B0601", "B0602"],
  A0302: ["B0503", "B0504", "B0603", "B0604"],
  A0303: ["B0505", "B0506", "B0605", "B0606"],
  A0304: ["B0507", "B0508", "B0607", "B0608"],
  A0305: ["B0509", "B0510", "B0609", "B0610"],
  A0306: ["B0511", "B0512", "B0611", "B0612"],
  A0307: ["B0513", "B0514", "B0613", "B0614"],
  A0308: ["B0515", "B0516", "B0615", "B0616"],
  A0401: ["B0701", "B0702", "B0801", "B0802"],
  A0402: ["B0703", "B0704", "B0803", "B0804"],
  A0403: ["B0705", "B0706", "B0805", "B0806"],
  A0404: ["B0707", "B0708", "B0807", "B0808"],
  A0405: ["B0709", "B0710", "B0809", "B0810"],
  A0406: ["B0711", "B0712", "B0811", "B0812"],
  A0407: ["B0713", "B0714", "B0813", "B0814"],
  A0408: ["B0715", "B0716", "B0815", "B0816"],
};

export const level2Config = [
  { cellName: "C0101", startX: 38784, startY: 51560, endX: 44964, endY: 60760 },
  { cellName: "C0102", startX: 44964, startY: 51560, endX: 51144, endY: 60760 },
  { cellName: "C0103", startX: 51144, startY: 51560, endX: 57324, endY: 60760 },
  { cellName: "C0104", startX: 57324, startY: 51560, endX: 63504, endY: 60760 },
  { cellName: "C0105", startX: 63504, startY: 51560, endX: 69684, endY: 60760 },
  { cellName: "C0106", startX: 69684, startY: 51560, endX: 75864, endY: 60760 },
  { cellName: "C0107", startX: 75864, startY: 51560, endX: 82044, endY: 60760 },
  { cellName: "C0108", startX: 82044, startY: 51560, endX: 88224, endY: 60760 },
  { cellName: "C0109", startX: 88224, startY: 51560, endX: 94404, endY: 60760 },
  {
    cellName: "C0110",
    startX: 94404,
    startY: 51560,
    endX: 100584,
    endY: 60760,
  },
  {
    cellName: "C0111",
    startX: 100584,
    startY: 51560,
    endX: 106764,
    endY: 60760,
  },
  {
    cellName: "C0112",
    startX: 106764,
    startY: 51560,
    endX: 112944,
    endY: 60760,
  },
  {
    cellName: "C0113",
    startX: 112944,
    startY: 51560,
    endX: 119124,
    endY: 60760,
  },
  {
    cellName: "C0114",
    startX: 119124,
    startY: 51560,
    endX: 125304,
    endY: 60760,
  },
  {
    cellName: "C0115",
    startX: 125304,
    startY: 51560,
    endX: 131484,
    endY: 60760,
  },
  {
    cellName: "C0116",
    startX: 131484,
    startY: 51560,
    endX: 137664,
    endY: 60760,
  },
  {
    cellName: "C0117",
    startX: 137664,
    startY: 51560,
    endX: 143844,
    endY: 60760,
  },
  {
    cellName: "C0118",
    startX: 143844,
    startY: 51560,
    endX: 150024,
    endY: 60760,
  },
  {
    cellName: "C0119",
    startX: 150024,
    startY: 51560,
    endX: 156204,
    endY: 60760,
  },
  {
    cellName: "C0120",
    startX: 156204,
    startY: 51560,
    endX: 162384,
    endY: 60760,
  },
  {
    cellName: "C0121",
    startX: 162384,
    startY: 51560,
    endX: 168564,
    endY: 60760,
  },
  {
    cellName: "C0122",
    startX: 168564,
    startY: 51560,
    endX: 174744,
    endY: 60760,
  },
  {
    cellName: "C0123",
    startX: 174744,
    startY: 51560,
    endX: 180924,
    endY: 60760,
  },
  {
    cellName: "C0124",
    startX: 180924,
    startY: 51560,
    endX: 187104,
    endY: 60760,
  },
  {
    cellName: "C0125",
    startX: 187104,
    startY: 51560,
    endX: 193284,
    endY: 60760,
  },
  {
    cellName: "C0126",
    startX: 193284,
    startY: 51560,
    endX: 199464,
    endY: 60760,
  },
  {
    cellName: "C0127",
    startX: 199464,
    startY: 51560,
    endX: 205644,
    endY: 60760,
  },
  {
    cellName: "C0128",
    startX: 205644,
    startY: 51560,
    endX: 211824,
    endY: 60760,
  },
  {
    cellName: "C0129",
    startX: 211824,
    startY: 51560,
    endX: 218004,
    endY: 60760,
  },
  {
    cellName: "C0130",
    startX: 218004,
    startY: 51560,
    endX: 224184,
    endY: 60760,
  },
  {
    cellName: "C0131",
    startX: 224184,
    startY: 51560,
    endX: 230364,
    endY: 60760,
  },
  {
    cellName: "C0132",
    startX: 230364,
    startY: 51560,
    endX: 236544,
    endY: 60760,
  },
  { cellName: "C0201", startX: 38784, startY: 60760, endX: 44964, endY: 69960 },
  { cellName: "C0202", startX: 44964, startY: 60760, endX: 51144, endY: 69960 },
  { cellName: "C0203", startX: 51144, startY: 60760, endX: 57324, endY: 69960 },
  { cellName: "C0204", startX: 57324, startY: 60760, endX: 63504, endY: 69960 },
  { cellName: "C0205", startX: 63504, startY: 60760, endX: 69684, endY: 69960 },
  { cellName: "C0206", startX: 69684, startY: 60760, endX: 75864, endY: 69960 },
  { cellName: "C0207", startX: 75864, startY: 60760, endX: 82044, endY: 69960 },
  { cellName: "C0208", startX: 82044, startY: 60760, endX: 88224, endY: 69960 },
  { cellName: "C0209", startX: 88224, startY: 60760, endX: 94404, endY: 69960 },
  {
    cellName: "C0210",
    startX: 94404,
    startY: 60760,
    endX: 100584,
    endY: 69960,
  },
  {
    cellName: "C0211",
    startX: 100584,
    startY: 60760,
    endX: 106764,
    endY: 69960,
  },
  {
    cellName: "C0212",
    startX: 106764,
    startY: 60760,
    endX: 112944,
    endY: 69960,
  },
  {
    cellName: "C0213",
    startX: 112944,
    startY: 60760,
    endX: 119124,
    endY: 69960,
  },
  {
    cellName: "C0214",
    startX: 119124,
    startY: 60760,
    endX: 125304,
    endY: 69960,
  },
  {
    cellName: "C0215",
    startX: 125304,
    startY: 60760,
    endX: 131484,
    endY: 69960,
  },
  {
    cellName: "C0216",
    startX: 131484,
    startY: 60760,
    endX: 137664,
    endY: 69960,
  },
  {
    cellName: "C0217",
    startX: 137664,
    startY: 60760,
    endX: 143844,
    endY: 69960,
  },
  {
    cellName: "C0218",
    startX: 143844,
    startY: 60760,
    endX: 150024,
    endY: 69960,
  },
  {
    cellName: "C0219",
    startX: 150024,
    startY: 60760,
    endX: 156204,
    endY: 69960,
  },
  {
    cellName: "C0220",
    startX: 156204,
    startY: 60760,
    endX: 162384,
    endY: 69960,
  },
  {
    cellName: "C0221",
    startX: 162384,
    startY: 60760,
    endX: 168564,
    endY: 69960,
  },
  {
    cellName: "C0222",
    startX: 168564,
    startY: 60760,
    endX: 174744,
    endY: 69960,
  },
  {
    cellName: "C0223",
    startX: 174744,
    startY: 60760,
    endX: 180924,
    endY: 69960,
  },
  {
    cellName: "C0224",
    startX: 180924,
    startY: 60760,
    endX: 187104,
    endY: 69960,
  },
  {
    cellName: "C0225",
    startX: 187104,
    startY: 60760,
    endX: 193284,
    endY: 69960,
  },
  {
    cellName: "C0226",
    startX: 193284,
    startY: 60760,
    endX: 199464,
    endY: 69960,
  },
  {
    cellName: "C0227",
    startX: 199464,
    startY: 60760,
    endX: 205644,
    endY: 69960,
  },
  {
    cellName: "C0228",
    startX: 205644,
    startY: 60760,
    endX: 211824,
    endY: 69960,
  },
  {
    cellName: "C0229",
    startX: 211824,
    startY: 60760,
    endX: 218004,
    endY: 69960,
  },
  {
    cellName: "C0230",
    startX: 218004,
    startY: 60760,
    endX: 224184,
    endY: 69960,
  },
  {
    cellName: "C0231",
    startX: 224184,
    startY: 60760,
    endX: 230364,
    endY: 69960,
  },
  {
    cellName: "C0232",
    startX: 230364,
    startY: 60760,
    endX: 236544,
    endY: 69960,
  },
  { cellName: "C0301", startX: 38784, startY: 69960, endX: 44964, endY: 79160 },
  { cellName: "C0302", startX: 44964, startY: 69960, endX: 51144, endY: 79160 },
  { cellName: "C0303", startX: 51144, startY: 69960, endX: 57324, endY: 79160 },
  { cellName: "C0304", startX: 57324, startY: 69960, endX: 63504, endY: 79160 },
  { cellName: "C0305", startX: 63504, startY: 69960, endX: 69684, endY: 79160 },
  { cellName: "C0306", startX: 69684, startY: 69960, endX: 75864, endY: 79160 },
  { cellName: "C0307", startX: 75864, startY: 69960, endX: 82044, endY: 79160 },
  { cellName: "C0308", startX: 82044, startY: 69960, endX: 88224, endY: 79160 },
  { cellName: "C0309", startX: 88224, startY: 69960, endX: 94404, endY: 79160 },
  {
    cellName: "C0310",
    startX: 94404,
    startY: 69960,
    endX: 100584,
    endY: 79160,
  },
  {
    cellName: "C0311",
    startX: 100584,
    startY: 69960,
    endX: 106764,
    endY: 79160,
  },
  {
    cellName: "C0312",
    startX: 106764,
    startY: 69960,
    endX: 112944,
    endY: 79160,
  },
  {
    cellName: "C0313",
    startX: 112944,
    startY: 69960,
    endX: 119124,
    endY: 79160,
  },
  {
    cellName: "C0314",
    startX: 119124,
    startY: 69960,
    endX: 125304,
    endY: 79160,
  },
  {
    cellName: "C0315",
    startX: 125304,
    startY: 69960,
    endX: 131484,
    endY: 79160,
  },
  {
    cellName: "C0316",
    startX: 131484,
    startY: 69960,
    endX: 137664,
    endY: 79160,
  },
  {
    cellName: "C0317",
    startX: 137664,
    startY: 69960,
    endX: 143844,
    endY: 79160,
  },
  {
    cellName: "C0318",
    startX: 143844,
    startY: 69960,
    endX: 150024,
    endY: 79160,
  },
  {
    cellName: "C0319",
    startX: 150024,
    startY: 69960,
    endX: 156204,
    endY: 79160,
  },
  {
    cellName: "C0320",
    startX: 156204,
    startY: 69960,
    endX: 162384,
    endY: 79160,
  },
  {
    cellName: "C0321",
    startX: 162384,
    startY: 69960,
    endX: 168564,
    endY: 79160,
  },
  {
    cellName: "C0322",
    startX: 168564,
    startY: 69960,
    endX: 174744,
    endY: 79160,
  },
  {
    cellName: "C0323",
    startX: 174744,
    startY: 69960,
    endX: 180924,
    endY: 79160,
  },
  {
    cellName: "C0324",
    startX: 180924,
    startY: 69960,
    endX: 187104,
    endY: 79160,
  },
  {
    cellName: "C0325",
    startX: 187104,
    startY: 69960,
    endX: 193284,
    endY: 79160,
  },
  {
    cellName: "C0326",
    startX: 193284,
    startY: 69960,
    endX: 199464,
    endY: 79160,
  },
  {
    cellName: "C0327",
    startX: 199464,
    startY: 69960,
    endX: 205644,
    endY: 79160,
  },
  {
    cellName: "C0328",
    startX: 205644,
    startY: 69960,
    endX: 211824,
    endY: 79160,
  },
  {
    cellName: "C0329",
    startX: 211824,
    startY: 69960,
    endX: 218004,
    endY: 79160,
  },
  {
    cellName: "C0330",
    startX: 218004,
    startY: 69960,
    endX: 224184,
    endY: 79160,
  },
  {
    cellName: "C0331",
    startX: 224184,
    startY: 69960,
    endX: 230364,
    endY: 79160,
  },
  {
    cellName: "C0332",
    startX: 230364,
    startY: 69960,
    endX: 236544,
    endY: 79160,
  },
  { cellName: "C0401", startX: 38784, startY: 79160, endX: 44964, endY: 88360 },
  { cellName: "C0402", startX: 44964, startY: 79160, endX: 51144, endY: 88360 },
  { cellName: "C0403", startX: 51144, startY: 79160, endX: 57324, endY: 88360 },
  { cellName: "C0404", startX: 57324, startY: 79160, endX: 63504, endY: 88360 },
  { cellName: "C0405", startX: 63504, startY: 79160, endX: 69684, endY: 88360 },
  { cellName: "C0406", startX: 69684, startY: 79160, endX: 75864, endY: 88360 },
  { cellName: "C0407", startX: 75864, startY: 79160, endX: 82044, endY: 88360 },
  { cellName: "C0408", startX: 82044, startY: 79160, endX: 88224, endY: 88360 },
  { cellName: "C0409", startX: 88224, startY: 79160, endX: 94404, endY: 88360 },
  {
    cellName: "C0410",
    startX: 94404,
    startY: 79160,
    endX: 100584,
    endY: 88360,
  },
  {
    cellName: "C0411",
    startX: 100584,
    startY: 79160,
    endX: 106764,
    endY: 88360,
  },
  {
    cellName: "C0412",
    startX: 106764,
    startY: 79160,
    endX: 112944,
    endY: 88360,
  },
  {
    cellName: "C0413",
    startX: 112944,
    startY: 79160,
    endX: 119124,
    endY: 88360,
  },
  {
    cellName: "C0414",
    startX: 119124,
    startY: 79160,
    endX: 125304,
    endY: 88360,
  },
  {
    cellName: "C0415",
    startX: 125304,
    startY: 79160,
    endX: 131484,
    endY: 88360,
  },
  {
    cellName: "C0416",
    startX: 131484,
    startY: 79160,
    endX: 137664,
    endY: 88360,
  },
  {
    cellName: "C0417",
    startX: 137664,
    startY: 79160,
    endX: 143844,
    endY: 88360,
  },
  {
    cellName: "C0418",
    startX: 143844,
    startY: 79160,
    endX: 150024,
    endY: 88360,
  },
  {
    cellName: "C0419",
    startX: 150024,
    startY: 79160,
    endX: 156204,
    endY: 88360,
  },
  {
    cellName: "C0420",
    startX: 156204,
    startY: 79160,
    endX: 162384,
    endY: 88360,
  },
  {
    cellName: "C0421",
    startX: 162384,
    startY: 79160,
    endX: 168564,
    endY: 88360,
  },
  {
    cellName: "C0422",
    startX: 168564,
    startY: 79160,
    endX: 174744,
    endY: 88360,
  },
  {
    cellName: "C0423",
    startX: 174744,
    startY: 79160,
    endX: 180924,
    endY: 88360,
  },
  {
    cellName: "C0424",
    startX: 180924,
    startY: 79160,
    endX: 187104,
    endY: 88360,
  },
  {
    cellName: "C0425",
    startX: 187104,
    startY: 79160,
    endX: 193284,
    endY: 88360,
  },
  {
    cellName: "C0426",
    startX: 193284,
    startY: 79160,
    endX: 199464,
    endY: 88360,
  },
  {
    cellName: "C0427",
    startX: 199464,
    startY: 79160,
    endX: 205644,
    endY: 88360,
  },
  {
    cellName: "C0428",
    startX: 205644,
    startY: 79160,
    endX: 211824,
    endY: 88360,
  },
  {
    cellName: "C0429",
    startX: 211824,
    startY: 79160,
    endX: 218004,
    endY: 88360,
  },
  {
    cellName: "C0430",
    startX: 218004,
    startY: 79160,
    endX: 224184,
    endY: 88360,
  },
  {
    cellName: "C0431",
    startX: 224184,
    startY: 79160,
    endX: 230364,
    endY: 88360,
  },
  {
    cellName: "C0432",
    startX: 230364,
    startY: 79160,
    endX: 236544,
    endY: 88360,
  },
  { cellName: "C0501", startX: 38784, startY: 88360, endX: 44964, endY: 97560 },
  { cellName: "C0502", startX: 44964, startY: 88360, endX: 51144, endY: 97560 },
  { cellName: "C0503", startX: 51144, startY: 88360, endX: 57324, endY: 97560 },
  { cellName: "C0504", startX: 57324, startY: 88360, endX: 63504, endY: 97560 },
  { cellName: "C0505", startX: 63504, startY: 88360, endX: 69684, endY: 97560 },
  { cellName: "C0506", startX: 69684, startY: 88360, endX: 75864, endY: 97560 },
  { cellName: "C0507", startX: 75864, startY: 88360, endX: 82044, endY: 97560 },
  { cellName: "C0508", startX: 82044, startY: 88360, endX: 88224, endY: 97560 },
  { cellName: "C0509", startX: 88224, startY: 88360, endX: 94404, endY: 97560 },
  {
    cellName: "C0510",
    startX: 94404,
    startY: 88360,
    endX: 100584,
    endY: 97560,
  },
  {
    cellName: "C0511",
    startX: 100584,
    startY: 88360,
    endX: 106764,
    endY: 97560,
  },
  {
    cellName: "C0512",
    startX: 106764,
    startY: 88360,
    endX: 112944,
    endY: 97560,
  },
  {
    cellName: "C0513",
    startX: 112944,
    startY: 88360,
    endX: 119124,
    endY: 97560,
  },
  {
    cellName: "C0514",
    startX: 119124,
    startY: 88360,
    endX: 125304,
    endY: 97560,
  },
  {
    cellName: "C0515",
    startX: 125304,
    startY: 88360,
    endX: 131484,
    endY: 97560,
  },
  {
    cellName: "C0516",
    startX: 131484,
    startY: 88360,
    endX: 137664,
    endY: 97560,
  },
  {
    cellName: "C0517",
    startX: 137664,
    startY: 88360,
    endX: 143844,
    endY: 97560,
  },
  {
    cellName: "C0518",
    startX: 143844,
    startY: 88360,
    endX: 150024,
    endY: 97560,
  },
  {
    cellName: "C0519",
    startX: 150024,
    startY: 88360,
    endX: 156204,
    endY: 97560,
  },
  {
    cellName: "C0520",
    startX: 156204,
    startY: 88360,
    endX: 162384,
    endY: 97560,
  },
  {
    cellName: "C0521",
    startX: 162384,
    startY: 88360,
    endX: 168564,
    endY: 97560,
  },
  {
    cellName: "C0522",
    startX: 168564,
    startY: 88360,
    endX: 174744,
    endY: 97560,
  },
  {
    cellName: "C0523",
    startX: 174744,
    startY: 88360,
    endX: 180924,
    endY: 97560,
  },
  {
    cellName: "C0524",
    startX: 180924,
    startY: 88360,
    endX: 187104,
    endY: 97560,
  },
  {
    cellName: "C0525",
    startX: 187104,
    startY: 88360,
    endX: 193284,
    endY: 97560,
  },
  {
    cellName: "C0526",
    startX: 193284,
    startY: 88360,
    endX: 199464,
    endY: 97560,
  },
  {
    cellName: "C0527",
    startX: 199464,
    startY: 88360,
    endX: 205644,
    endY: 97560,
  },
  {
    cellName: "C0528",
    startX: 205644,
    startY: 88360,
    endX: 211824,
    endY: 97560,
  },
  {
    cellName: "C0529",
    startX: 211824,
    startY: 88360,
    endX: 218004,
    endY: 97560,
  },
  {
    cellName: "C0530",
    startX: 218004,
    startY: 88360,
    endX: 224184,
    endY: 97560,
  },
  {
    cellName: "C0531",
    startX: 224184,
    startY: 88360,
    endX: 230364,
    endY: 97560,
  },
  {
    cellName: "C0532",
    startX: 230364,
    startY: 88360,
    endX: 236544,
    endY: 97560,
  },
  {
    cellName: "C0601",
    startX: 38784,
    startY: 97560,
    endX: 44964,
    endY: 106760,
  },
  {
    cellName: "C0602",
    startX: 44964,
    startY: 97560,
    endX: 51144,
    endY: 106760,
  },
  {
    cellName: "C0603",
    startX: 51144,
    startY: 97560,
    endX: 57324,
    endY: 106760,
  },
  {
    cellName: "C0604",
    startX: 57324,
    startY: 97560,
    endX: 63504,
    endY: 106760,
  },
  {
    cellName: "C0605",
    startX: 63504,
    startY: 97560,
    endX: 69684,
    endY: 106760,
  },
  {
    cellName: "C0606",
    startX: 69684,
    startY: 97560,
    endX: 75864,
    endY: 106760,
  },
  {
    cellName: "C0607",
    startX: 75864,
    startY: 97560,
    endX: 82044,
    endY: 106760,
  },
  {
    cellName: "C0608",
    startX: 82044,
    startY: 97560,
    endX: 88224,
    endY: 106760,
  },
  {
    cellName: "C0609",
    startX: 88224,
    startY: 97560,
    endX: 94404,
    endY: 106760,
  },
  {
    cellName: "C0610",
    startX: 94404,
    startY: 97560,
    endX: 100584,
    endY: 106760,
  },
  {
    cellName: "C0611",
    startX: 100584,
    startY: 97560,
    endX: 106764,
    endY: 106760,
  },
  {
    cellName: "C0612",
    startX: 106764,
    startY: 97560,
    endX: 112944,
    endY: 106760,
  },
  {
    cellName: "C0613",
    startX: 112944,
    startY: 97560,
    endX: 119124,
    endY: 106760,
  },
  {
    cellName: "C0614",
    startX: 119124,
    startY: 97560,
    endX: 125304,
    endY: 106760,
  },
  {
    cellName: "C0615",
    startX: 125304,
    startY: 97560,
    endX: 131484,
    endY: 106760,
  },
  {
    cellName: "C0616",
    startX: 131484,
    startY: 97560,
    endX: 137664,
    endY: 106760,
  },
  {
    cellName: "C0617",
    startX: 137664,
    startY: 97560,
    endX: 143844,
    endY: 106760,
  },
  {
    cellName: "C0618",
    startX: 143844,
    startY: 97560,
    endX: 150024,
    endY: 106760,
  },
  {
    cellName: "C0619",
    startX: 150024,
    startY: 97560,
    endX: 156204,
    endY: 106760,
  },
  {
    cellName: "C0620",
    startX: 156204,
    startY: 97560,
    endX: 162384,
    endY: 106760,
  },
  {
    cellName: "C0621",
    startX: 162384,
    startY: 97560,
    endX: 168564,
    endY: 106760,
  },
  {
    cellName: "C0622",
    startX: 168564,
    startY: 97560,
    endX: 174744,
    endY: 106760,
  },
  {
    cellName: "C0623",
    startX: 174744,
    startY: 97560,
    endX: 180924,
    endY: 106760,
  },
  {
    cellName: "C0624",
    startX: 180924,
    startY: 97560,
    endX: 187104,
    endY: 106760,
  },
  {
    cellName: "C0625",
    startX: 187104,
    startY: 97560,
    endX: 193284,
    endY: 106760,
  },
  {
    cellName: "C0626",
    startX: 193284,
    startY: 97560,
    endX: 199464,
    endY: 106760,
  },
  {
    cellName: "C0627",
    startX: 199464,
    startY: 97560,
    endX: 205644,
    endY: 106760,
  },
  {
    cellName: "C0628",
    startX: 205644,
    startY: 97560,
    endX: 211824,
    endY: 106760,
  },
  {
    cellName: "C0629",
    startX: 211824,
    startY: 97560,
    endX: 218004,
    endY: 106760,
  },
  {
    cellName: "C0630",
    startX: 218004,
    startY: 97560,
    endX: 224184,
    endY: 106760,
  },
  {
    cellName: "C0631",
    startX: 224184,
    startY: 97560,
    endX: 230364,
    endY: 106760,
  },
  {
    cellName: "C0632",
    startX: 230364,
    startY: 97560,
    endX: 236544,
    endY: 106760,
  },
  {
    cellName: "C0701",
    startX: 38784,
    startY: 106760,
    endX: 44964,
    endY: 115960,
  },
  {
    cellName: "C0702",
    startX: 44964,
    startY: 106760,
    endX: 51144,
    endY: 115960,
  },
  {
    cellName: "C0703",
    startX: 51144,
    startY: 106760,
    endX: 57324,
    endY: 115960,
  },
  {
    cellName: "C0704",
    startX: 57324,
    startY: 106760,
    endX: 63504,
    endY: 115960,
  },
  {
    cellName: "C0705",
    startX: 63504,
    startY: 106760,
    endX: 69684,
    endY: 115960,
  },
  {
    cellName: "C0706",
    startX: 69684,
    startY: 106760,
    endX: 75864,
    endY: 115960,
  },
  {
    cellName: "C0707",
    startX: 75864,
    startY: 106760,
    endX: 82044,
    endY: 115960,
  },
  {
    cellName: "C0708",
    startX: 82044,
    startY: 106760,
    endX: 88224,
    endY: 115960,
  },
  {
    cellName: "C0709",
    startX: 88224,
    startY: 106760,
    endX: 94404,
    endY: 115960,
  },
  {
    cellName: "C0710",
    startX: 94404,
    startY: 106760,
    endX: 100584,
    endY: 115960,
  },
  {
    cellName: "C0711",
    startX: 100584,
    startY: 106760,
    endX: 106764,
    endY: 115960,
  },
  {
    cellName: "C0712",
    startX: 106764,
    startY: 106760,
    endX: 112944,
    endY: 115960,
  },
  {
    cellName: "C0713",
    startX: 112944,
    startY: 106760,
    endX: 119124,
    endY: 115960,
  },
  {
    cellName: "C0714",
    startX: 119124,
    startY: 106760,
    endX: 125304,
    endY: 115960,
  },
  {
    cellName: "C0715",
    startX: 125304,
    startY: 106760,
    endX: 131484,
    endY: 115960,
  },
  {
    cellName: "C0716",
    startX: 131484,
    startY: 106760,
    endX: 137664,
    endY: 115960,
  },
  {
    cellName: "C0717",
    startX: 137664,
    startY: 106760,
    endX: 143844,
    endY: 115960,
  },
  {
    cellName: "C0718",
    startX: 143844,
    startY: 106760,
    endX: 150024,
    endY: 115960,
  },
  {
    cellName: "C0719",
    startX: 150024,
    startY: 106760,
    endX: 156204,
    endY: 115960,
  },
  {
    cellName: "C0720",
    startX: 156204,
    startY: 106760,
    endX: 162384,
    endY: 115960,
  },
  {
    cellName: "C0721",
    startX: 162384,
    startY: 106760,
    endX: 168564,
    endY: 115960,
  },
  {
    cellName: "C0722",
    startX: 168564,
    startY: 106760,
    endX: 174744,
    endY: 115960,
  },
  {
    cellName: "C0723",
    startX: 174744,
    startY: 106760,
    endX: 180924,
    endY: 115960,
  },
  {
    cellName: "C0724",
    startX: 180924,
    startY: 106760,
    endX: 187104,
    endY: 115960,
  },
  {
    cellName: "C0725",
    startX: 187104,
    startY: 106760,
    endX: 193284,
    endY: 115960,
  },
  {
    cellName: "C0726",
    startX: 193284,
    startY: 106760,
    endX: 199464,
    endY: 115960,
  },
  {
    cellName: "C0727",
    startX: 199464,
    startY: 106760,
    endX: 205644,
    endY: 115960,
  },
  {
    cellName: "C0728",
    startX: 205644,
    startY: 106760,
    endX: 211824,
    endY: 115960,
  },
  {
    cellName: "C0729",
    startX: 211824,
    startY: 106760,
    endX: 218004,
    endY: 115960,
  },
  {
    cellName: "C0730",
    startX: 218004,
    startY: 106760,
    endX: 224184,
    endY: 115960,
  },
  {
    cellName: "C0731",
    startX: 224184,
    startY: 106760,
    endX: 230364,
    endY: 115960,
  },
  {
    cellName: "C0732",
    startX: 230364,
    startY: 106760,
    endX: 236544,
    endY: 115960,
  },
  {
    cellName: "C0801",
    startX: 38784,
    startY: 115960,
    endX: 44964,
    endY: 125160,
  },
  {
    cellName: "C0802",
    startX: 44964,
    startY: 115960,
    endX: 51144,
    endY: 125160,
  },
  {
    cellName: "C0803",
    startX: 51144,
    startY: 115960,
    endX: 57324,
    endY: 125160,
  },
  {
    cellName: "C0804",
    startX: 57324,
    startY: 115960,
    endX: 63504,
    endY: 125160,
  },
  {
    cellName: "C0805",
    startX: 63504,
    startY: 115960,
    endX: 69684,
    endY: 125160,
  },
  {
    cellName: "C0806",
    startX: 69684,
    startY: 115960,
    endX: 75864,
    endY: 125160,
  },
  {
    cellName: "C0807",
    startX: 75864,
    startY: 115960,
    endX: 82044,
    endY: 125160,
  },
  {
    cellName: "C0808",
    startX: 82044,
    startY: 115960,
    endX: 88224,
    endY: 125160,
  },
  {
    cellName: "C0809",
    startX: 88224,
    startY: 115960,
    endX: 94404,
    endY: 125160,
  },
  {
    cellName: "C0810",
    startX: 94404,
    startY: 115960,
    endX: 100584,
    endY: 125160,
  },
  {
    cellName: "C0811",
    startX: 100584,
    startY: 115960,
    endX: 106764,
    endY: 125160,
  },
  {
    cellName: "C0812",
    startX: 106764,
    startY: 115960,
    endX: 112944,
    endY: 125160,
  },
  {
    cellName: "C0813",
    startX: 112944,
    startY: 115960,
    endX: 119124,
    endY: 125160,
  },
  {
    cellName: "C0814",
    startX: 119124,
    startY: 115960,
    endX: 125304,
    endY: 125160,
  },
  {
    cellName: "C0815",
    startX: 125304,
    startY: 115960,
    endX: 131484,
    endY: 125160,
  },
  {
    cellName: "C0816",
    startX: 131484,
    startY: 115960,
    endX: 137664,
    endY: 125160,
  },
  {
    cellName: "C0817",
    startX: 137664,
    startY: 115960,
    endX: 143844,
    endY: 125160,
  },
  {
    cellName: "C0818",
    startX: 143844,
    startY: 115960,
    endX: 150024,
    endY: 125160,
  },
  {
    cellName: "C0819",
    startX: 150024,
    startY: 115960,
    endX: 156204,
    endY: 125160,
  },
  {
    cellName: "C0820",
    startX: 156204,
    startY: 115960,
    endX: 162384,
    endY: 125160,
  },
  {
    cellName: "C0821",
    startX: 162384,
    startY: 115960,
    endX: 168564,
    endY: 125160,
  },
  {
    cellName: "C0822",
    startX: 168564,
    startY: 115960,
    endX: 174744,
    endY: 125160,
  },
  {
    cellName: "C0823",
    startX: 174744,
    startY: 115960,
    endX: 180924,
    endY: 125160,
  },
  {
    cellName: "C0824",
    startX: 180924,
    startY: 115960,
    endX: 187104,
    endY: 125160,
  },
  {
    cellName: "C0825",
    startX: 187104,
    startY: 115960,
    endX: 193284,
    endY: 125160,
  },
  {
    cellName: "C0826",
    startX: 193284,
    startY: 115960,
    endX: 199464,
    endY: 125160,
  },
  {
    cellName: "C0827",
    startX: 199464,
    startY: 115960,
    endX: 205644,
    endY: 125160,
  },
  {
    cellName: "C0828",
    startX: 205644,
    startY: 115960,
    endX: 211824,
    endY: 125160,
  },
  {
    cellName: "C0829",
    startX: 211824,
    startY: 115960,
    endX: 218004,
    endY: 125160,
  },
  {
    cellName: "C0830",
    startX: 218004,
    startY: 115960,
    endX: 224184,
    endY: 125160,
  },
  {
    cellName: "C0831",
    startX: 224184,
    startY: 115960,
    endX: 230364,
    endY: 125160,
  },
  {
    cellName: "C0832",
    startX: 230364,
    startY: 115960,
    endX: 236544,
    endY: 125160,
  },
  {
    cellName: "C0901",
    startX: 38784,
    startY: 125160,
    endX: 44964,
    endY: 134360,
  },
  {
    cellName: "C0902",
    startX: 44964,
    startY: 125160,
    endX: 51144,
    endY: 134360,
  },
  {
    cellName: "C0903",
    startX: 51144,
    startY: 125160,
    endX: 57324,
    endY: 134360,
  },
  {
    cellName: "C0904",
    startX: 57324,
    startY: 125160,
    endX: 63504,
    endY: 134360,
  },
  {
    cellName: "C0905",
    startX: 63504,
    startY: 125160,
    endX: 69684,
    endY: 134360,
  },
  {
    cellName: "C0906",
    startX: 69684,
    startY: 125160,
    endX: 75864,
    endY: 134360,
  },
  {
    cellName: "C0907",
    startX: 75864,
    startY: 125160,
    endX: 82044,
    endY: 134360,
  },
  {
    cellName: "C0908",
    startX: 82044,
    startY: 125160,
    endX: 88224,
    endY: 134360,
  },
  {
    cellName: "C0909",
    startX: 88224,
    startY: 125160,
    endX: 94404,
    endY: 134360,
  },
  {
    cellName: "C0910",
    startX: 94404,
    startY: 125160,
    endX: 100584,
    endY: 134360,
  },
  {
    cellName: "C0911",
    startX: 100584,
    startY: 125160,
    endX: 106764,
    endY: 134360,
  },
  {
    cellName: "C0912",
    startX: 106764,
    startY: 125160,
    endX: 112944,
    endY: 134360,
  },
  {
    cellName: "C0913",
    startX: 112944,
    startY: 125160,
    endX: 119124,
    endY: 134360,
  },
  {
    cellName: "C0914",
    startX: 119124,
    startY: 125160,
    endX: 125304,
    endY: 134360,
  },
  {
    cellName: "C0915",
    startX: 125304,
    startY: 125160,
    endX: 131484,
    endY: 134360,
  },
  {
    cellName: "C0916",
    startX: 131484,
    startY: 125160,
    endX: 137664,
    endY: 134360,
  },
  {
    cellName: "C0917",
    startX: 137664,
    startY: 125160,
    endX: 143844,
    endY: 134360,
  },
  {
    cellName: "C0918",
    startX: 143844,
    startY: 125160,
    endX: 150024,
    endY: 134360,
  },
  {
    cellName: "C0919",
    startX: 150024,
    startY: 125160,
    endX: 156204,
    endY: 134360,
  },
  {
    cellName: "C0920",
    startX: 156204,
    startY: 125160,
    endX: 162384,
    endY: 134360,
  },
  {
    cellName: "C0921",
    startX: 162384,
    startY: 125160,
    endX: 168564,
    endY: 134360,
  },
  {
    cellName: "C0922",
    startX: 168564,
    startY: 125160,
    endX: 174744,
    endY: 134360,
  },
  {
    cellName: "C0923",
    startX: 174744,
    startY: 125160,
    endX: 180924,
    endY: 134360,
  },
  {
    cellName: "C0924",
    startX: 180924,
    startY: 125160,
    endX: 187104,
    endY: 134360,
  },
  {
    cellName: "C0925",
    startX: 187104,
    startY: 125160,
    endX: 193284,
    endY: 134360,
  },
  {
    cellName: "C0926",
    startX: 193284,
    startY: 125160,
    endX: 199464,
    endY: 134360,
  },
  {
    cellName: "C0927",
    startX: 199464,
    startY: 125160,
    endX: 205644,
    endY: 134360,
  },
  {
    cellName: "C0928",
    startX: 205644,
    startY: 125160,
    endX: 211824,
    endY: 134360,
  },
  {
    cellName: "C0929",
    startX: 211824,
    startY: 125160,
    endX: 218004,
    endY: 134360,
  },
  {
    cellName: "C0930",
    startX: 218004,
    startY: 125160,
    endX: 224184,
    endY: 134360,
  },
  {
    cellName: "C0931",
    startX: 224184,
    startY: 125160,
    endX: 230364,
    endY: 134360,
  },
  {
    cellName: "C0932",
    startX: 230364,
    startY: 125160,
    endX: 236544,
    endY: 134360,
  },
  {
    cellName: "C1001",
    startX: 38784,
    startY: 134360,
    endX: 44964,
    endY: 143560,
  },
  {
    cellName: "C1002",
    startX: 44964,
    startY: 134360,
    endX: 51144,
    endY: 143560,
  },
  {
    cellName: "C1003",
    startX: 51144,
    startY: 134360,
    endX: 57324,
    endY: 143560,
  },
  {
    cellName: "C1004",
    startX: 57324,
    startY: 134360,
    endX: 63504,
    endY: 143560,
  },
  {
    cellName: "C1005",
    startX: 63504,
    startY: 134360,
    endX: 69684,
    endY: 143560,
  },
  {
    cellName: "C1006",
    startX: 69684,
    startY: 134360,
    endX: 75864,
    endY: 143560,
  },
  {
    cellName: "C1007",
    startX: 75864,
    startY: 134360,
    endX: 82044,
    endY: 143560,
  },
  {
    cellName: "C1008",
    startX: 82044,
    startY: 134360,
    endX: 88224,
    endY: 143560,
  },
  {
    cellName: "C1009",
    startX: 88224,
    startY: 134360,
    endX: 94404,
    endY: 143560,
  },
  {
    cellName: "C1010",
    startX: 94404,
    startY: 134360,
    endX: 100584,
    endY: 143560,
  },
  {
    cellName: "C1011",
    startX: 100584,
    startY: 134360,
    endX: 106764,
    endY: 143560,
  },
  {
    cellName: "C1012",
    startX: 106764,
    startY: 134360,
    endX: 112944,
    endY: 143560,
  },
  {
    cellName: "C1013",
    startX: 112944,
    startY: 134360,
    endX: 119124,
    endY: 143560,
  },
  {
    cellName: "C1014",
    startX: 119124,
    startY: 134360,
    endX: 125304,
    endY: 143560,
  },
  {
    cellName: "C1015",
    startX: 125304,
    startY: 134360,
    endX: 131484,
    endY: 143560,
  },
  {
    cellName: "C1016",
    startX: 131484,
    startY: 134360,
    endX: 137664,
    endY: 143560,
  },
  {
    cellName: "C1017",
    startX: 137664,
    startY: 134360,
    endX: 143844,
    endY: 143560,
  },
  {
    cellName: "C1018",
    startX: 143844,
    startY: 134360,
    endX: 150024,
    endY: 143560,
  },
  {
    cellName: "C1019",
    startX: 150024,
    startY: 134360,
    endX: 156204,
    endY: 143560,
  },
  {
    cellName: "C1020",
    startX: 156204,
    startY: 134360,
    endX: 162384,
    endY: 143560,
  },
  {
    cellName: "C1021",
    startX: 162384,
    startY: 134360,
    endX: 168564,
    endY: 143560,
  },
  {
    cellName: "C1022",
    startX: 168564,
    startY: 134360,
    endX: 174744,
    endY: 143560,
  },
  {
    cellName: "C1023",
    startX: 174744,
    startY: 134360,
    endX: 180924,
    endY: 143560,
  },
  {
    cellName: "C1024",
    startX: 180924,
    startY: 134360,
    endX: 187104,
    endY: 143560,
  },
  {
    cellName: "C1025",
    startX: 187104,
    startY: 134360,
    endX: 193284,
    endY: 143560,
  },
  {
    cellName: "C1026",
    startX: 193284,
    startY: 134360,
    endX: 199464,
    endY: 143560,
  },
  {
    cellName: "C1027",
    startX: 199464,
    startY: 134360,
    endX: 205644,
    endY: 143560,
  },
  {
    cellName: "C1028",
    startX: 205644,
    startY: 134360,
    endX: 211824,
    endY: 143560,
  },
  {
    cellName: "C1029",
    startX: 211824,
    startY: 134360,
    endX: 218004,
    endY: 143560,
  },
  {
    cellName: "C1030",
    startX: 218004,
    startY: 134360,
    endX: 224184,
    endY: 143560,
  },
  {
    cellName: "C1031",
    startX: 224184,
    startY: 134360,
    endX: 230364,
    endY: 143560,
  },
  {
    cellName: "C1032",
    startX: 230364,
    startY: 134360,
    endX: 236544,
    endY: 143560,
  },
  {
    cellName: "C1101",
    startX: 38784,
    startY: 143560,
    endX: 44964,
    endY: 152760,
  },
  {
    cellName: "C1102",
    startX: 44964,
    startY: 143560,
    endX: 51144,
    endY: 152760,
  },
  {
    cellName: "C1103",
    startX: 51144,
    startY: 143560,
    endX: 57324,
    endY: 152760,
  },
  {
    cellName: "C1104",
    startX: 57324,
    startY: 143560,
    endX: 63504,
    endY: 152760,
  },
  {
    cellName: "C1105",
    startX: 63504,
    startY: 143560,
    endX: 69684,
    endY: 152760,
  },
  {
    cellName: "C1106",
    startX: 69684,
    startY: 143560,
    endX: 75864,
    endY: 152760,
  },
  {
    cellName: "C1107",
    startX: 75864,
    startY: 143560,
    endX: 82044,
    endY: 152760,
  },
  {
    cellName: "C1108",
    startX: 82044,
    startY: 143560,
    endX: 88224,
    endY: 152760,
  },
  {
    cellName: "C1109",
    startX: 88224,
    startY: 143560,
    endX: 94404,
    endY: 152760,
  },
  {
    cellName: "C1110",
    startX: 94404,
    startY: 143560,
    endX: 100584,
    endY: 152760,
  },
  {
    cellName: "C1111",
    startX: 100584,
    startY: 143560,
    endX: 106764,
    endY: 152760,
  },
  {
    cellName: "C1112",
    startX: 106764,
    startY: 143560,
    endX: 112944,
    endY: 152760,
  },
  {
    cellName: "C1113",
    startX: 112944,
    startY: 143560,
    endX: 119124,
    endY: 152760,
  },
  {
    cellName: "C1114",
    startX: 119124,
    startY: 143560,
    endX: 125304,
    endY: 152760,
  },
  {
    cellName: "C1115",
    startX: 125304,
    startY: 143560,
    endX: 131484,
    endY: 152760,
  },
  {
    cellName: "C1116",
    startX: 131484,
    startY: 143560,
    endX: 137664,
    endY: 152760,
  },
  {
    cellName: "C1117",
    startX: 137664,
    startY: 143560,
    endX: 143844,
    endY: 152760,
  },
  {
    cellName: "C1118",
    startX: 143844,
    startY: 143560,
    endX: 150024,
    endY: 152760,
  },
  {
    cellName: "C1119",
    startX: 150024,
    startY: 143560,
    endX: 156204,
    endY: 152760,
  },
  {
    cellName: "C1120",
    startX: 156204,
    startY: 143560,
    endX: 162384,
    endY: 152760,
  },
  {
    cellName: "C1121",
    startX: 162384,
    startY: 143560,
    endX: 168564,
    endY: 152760,
  },
  {
    cellName: "C1122",
    startX: 168564,
    startY: 143560,
    endX: 174744,
    endY: 152760,
  },
  {
    cellName: "C1123",
    startX: 174744,
    startY: 143560,
    endX: 180924,
    endY: 152760,
  },
  {
    cellName: "C1124",
    startX: 180924,
    startY: 143560,
    endX: 187104,
    endY: 152760,
  },
  {
    cellName: "C1125",
    startX: 187104,
    startY: 143560,
    endX: 193284,
    endY: 152760,
  },
  {
    cellName: "C1126",
    startX: 193284,
    startY: 143560,
    endX: 199464,
    endY: 152760,
  },
  {
    cellName: "C1127",
    startX: 199464,
    startY: 143560,
    endX: 205644,
    endY: 152760,
  },
  {
    cellName: "C1128",
    startX: 205644,
    startY: 143560,
    endX: 211824,
    endY: 152760,
  },
  {
    cellName: "C1129",
    startX: 211824,
    startY: 143560,
    endX: 218004,
    endY: 152760,
  },
  {
    cellName: "C1130",
    startX: 218004,
    startY: 143560,
    endX: 224184,
    endY: 152760,
  },
  {
    cellName: "C1131",
    startX: 224184,
    startY: 143560,
    endX: 230364,
    endY: 152760,
  },
  {
    cellName: "C1132",
    startX: 230364,
    startY: 143560,
    endX: 236544,
    endY: 152760,
  },
  {
    cellName: "C1201",
    startX: 38784,
    startY: 152760,
    endX: 44964,
    endY: 161960,
  },
  {
    cellName: "C1202",
    startX: 44964,
    startY: 152760,
    endX: 51144,
    endY: 161960,
  },
  {
    cellName: "C1203",
    startX: 51144,
    startY: 152760,
    endX: 57324,
    endY: 161960,
  },
  {
    cellName: "C1204",
    startX: 57324,
    startY: 152760,
    endX: 63504,
    endY: 161960,
  },
  {
    cellName: "C1205",
    startX: 63504,
    startY: 152760,
    endX: 69684,
    endY: 161960,
  },
  {
    cellName: "C1206",
    startX: 69684,
    startY: 152760,
    endX: 75864,
    endY: 161960,
  },
  {
    cellName: "C1207",
    startX: 75864,
    startY: 152760,
    endX: 82044,
    endY: 161960,
  },
  {
    cellName: "C1208",
    startX: 82044,
    startY: 152760,
    endX: 88224,
    endY: 161960,
  },
  {
    cellName: "C1209",
    startX: 88224,
    startY: 152760,
    endX: 94404,
    endY: 161960,
  },
  {
    cellName: "C1210",
    startX: 94404,
    startY: 152760,
    endX: 100584,
    endY: 161960,
  },
  {
    cellName: "C1211",
    startX: 100584,
    startY: 152760,
    endX: 106764,
    endY: 161960,
  },
  {
    cellName: "C1212",
    startX: 106764,
    startY: 152760,
    endX: 112944,
    endY: 161960,
  },
  {
    cellName: "C1213",
    startX: 112944,
    startY: 152760,
    endX: 119124,
    endY: 161960,
  },
  {
    cellName: "C1214",
    startX: 119124,
    startY: 152760,
    endX: 125304,
    endY: 161960,
  },
  {
    cellName: "C1215",
    startX: 125304,
    startY: 152760,
    endX: 131484,
    endY: 161960,
  },
  {
    cellName: "C1216",
    startX: 131484,
    startY: 152760,
    endX: 137664,
    endY: 161960,
  },
  {
    cellName: "C1217",
    startX: 137664,
    startY: 152760,
    endX: 143844,
    endY: 161960,
  },
  {
    cellName: "C1218",
    startX: 143844,
    startY: 152760,
    endX: 150024,
    endY: 161960,
  },
  {
    cellName: "C1219",
    startX: 150024,
    startY: 152760,
    endX: 156204,
    endY: 161960,
  },
  {
    cellName: "C1220",
    startX: 156204,
    startY: 152760,
    endX: 162384,
    endY: 161960,
  },
  {
    cellName: "C1221",
    startX: 162384,
    startY: 152760,
    endX: 168564,
    endY: 161960,
  },
  {
    cellName: "C1222",
    startX: 168564,
    startY: 152760,
    endX: 174744,
    endY: 161960,
  },
  {
    cellName: "C1223",
    startX: 174744,
    startY: 152760,
    endX: 180924,
    endY: 161960,
  },
  {
    cellName: "C1224",
    startX: 180924,
    startY: 152760,
    endX: 187104,
    endY: 161960,
  },
  {
    cellName: "C1225",
    startX: 187104,
    startY: 152760,
    endX: 193284,
    endY: 161960,
  },
  {
    cellName: "C1226",
    startX: 193284,
    startY: 152760,
    endX: 199464,
    endY: 161960,
  },
  {
    cellName: "C1227",
    startX: 199464,
    startY: 152760,
    endX: 205644,
    endY: 161960,
  },
  {
    cellName: "C1228",
    startX: 205644,
    startY: 152760,
    endX: 211824,
    endY: 161960,
  },
  {
    cellName: "C1229",
    startX: 211824,
    startY: 152760,
    endX: 218004,
    endY: 161960,
  },
  {
    cellName: "C1230",
    startX: 218004,
    startY: 152760,
    endX: 224184,
    endY: 161960,
  },
  {
    cellName: "C1231",
    startX: 224184,
    startY: 152760,
    endX: 230364,
    endY: 161960,
  },
  {
    cellName: "C1232",
    startX: 230364,
    startY: 152760,
    endX: 236544,
    endY: 161960,
  },
  {
    cellName: "C1301",
    startX: 38784,
    startY: 161960,
    endX: 44964,
    endY: 171160,
  },
  {
    cellName: "C1302",
    startX: 44964,
    startY: 161960,
    endX: 51144,
    endY: 171160,
  },
  {
    cellName: "C1303",
    startX: 51144,
    startY: 161960,
    endX: 57324,
    endY: 171160,
  },
  {
    cellName: "C1304",
    startX: 57324,
    startY: 161960,
    endX: 63504,
    endY: 171160,
  },
  {
    cellName: "C1305",
    startX: 63504,
    startY: 161960,
    endX: 69684,
    endY: 171160,
  },
  {
    cellName: "C1306",
    startX: 69684,
    startY: 161960,
    endX: 75864,
    endY: 171160,
  },
  {
    cellName: "C1307",
    startX: 75864,
    startY: 161960,
    endX: 82044,
    endY: 171160,
  },
  {
    cellName: "C1308",
    startX: 82044,
    startY: 161960,
    endX: 88224,
    endY: 171160,
  },
  {
    cellName: "C1309",
    startX: 88224,
    startY: 161960,
    endX: 94404,
    endY: 171160,
  },
  {
    cellName: "C1310",
    startX: 94404,
    startY: 161960,
    endX: 100584,
    endY: 171160,
  },
  {
    cellName: "C1311",
    startX: 100584,
    startY: 161960,
    endX: 106764,
    endY: 171160,
  },
  {
    cellName: "C1312",
    startX: 106764,
    startY: 161960,
    endX: 112944,
    endY: 171160,
  },
  {
    cellName: "C1313",
    startX: 112944,
    startY: 161960,
    endX: 119124,
    endY: 171160,
  },
  {
    cellName: "C1314",
    startX: 119124,
    startY: 161960,
    endX: 125304,
    endY: 171160,
  },
  {
    cellName: "C1315",
    startX: 125304,
    startY: 161960,
    endX: 131484,
    endY: 171160,
  },
  {
    cellName: "C1316",
    startX: 131484,
    startY: 161960,
    endX: 137664,
    endY: 171160,
  },
  {
    cellName: "C1317",
    startX: 137664,
    startY: 161960,
    endX: 143844,
    endY: 171160,
  },
  {
    cellName: "C1318",
    startX: 143844,
    startY: 161960,
    endX: 150024,
    endY: 171160,
  },
  {
    cellName: "C1319",
    startX: 150024,
    startY: 161960,
    endX: 156204,
    endY: 171160,
  },
  {
    cellName: "C1320",
    startX: 156204,
    startY: 161960,
    endX: 162384,
    endY: 171160,
  },
  {
    cellName: "C1321",
    startX: 162384,
    startY: 161960,
    endX: 168564,
    endY: 171160,
  },
  {
    cellName: "C1322",
    startX: 168564,
    startY: 161960,
    endX: 174744,
    endY: 171160,
  },
  {
    cellName: "C1323",
    startX: 174744,
    startY: 161960,
    endX: 180924,
    endY: 171160,
  },
  {
    cellName: "C1324",
    startX: 180924,
    startY: 161960,
    endX: 187104,
    endY: 171160,
  },
  {
    cellName: "C1325",
    startX: 187104,
    startY: 161960,
    endX: 193284,
    endY: 171160,
  },
  {
    cellName: "C1326",
    startX: 193284,
    startY: 161960,
    endX: 199464,
    endY: 171160,
  },
  {
    cellName: "C1327",
    startX: 199464,
    startY: 161960,
    endX: 205644,
    endY: 171160,
  },
  {
    cellName: "C1328",
    startX: 205644,
    startY: 161960,
    endX: 211824,
    endY: 171160,
  },
  {
    cellName: "C1329",
    startX: 211824,
    startY: 161960,
    endX: 218004,
    endY: 171160,
  },
  {
    cellName: "C1330",
    startX: 218004,
    startY: 161960,
    endX: 224184,
    endY: 171160,
  },
  {
    cellName: "C1331",
    startX: 224184,
    startY: 161960,
    endX: 230364,
    endY: 171160,
  },
  {
    cellName: "C1332",
    startX: 230364,
    startY: 161960,
    endX: 236544,
    endY: 171160,
  },
  {
    cellName: "C1401",
    startX: 38784,
    startY: 171160,
    endX: 44964,
    endY: 180360,
  },
  {
    cellName: "C1402",
    startX: 44964,
    startY: 171160,
    endX: 51144,
    endY: 180360,
  },
  {
    cellName: "C1403",
    startX: 51144,
    startY: 171160,
    endX: 57324,
    endY: 180360,
  },
  {
    cellName: "C1404",
    startX: 57324,
    startY: 171160,
    endX: 63504,
    endY: 180360,
  },
  {
    cellName: "C1405",
    startX: 63504,
    startY: 171160,
    endX: 69684,
    endY: 180360,
  },
  {
    cellName: "C1406",
    startX: 69684,
    startY: 171160,
    endX: 75864,
    endY: 180360,
  },
  {
    cellName: "C1407",
    startX: 75864,
    startY: 171160,
    endX: 82044,
    endY: 180360,
  },
  {
    cellName: "C1408",
    startX: 82044,
    startY: 171160,
    endX: 88224,
    endY: 180360,
  },
  {
    cellName: "C1409",
    startX: 88224,
    startY: 171160,
    endX: 94404,
    endY: 180360,
  },
  {
    cellName: "C1410",
    startX: 94404,
    startY: 171160,
    endX: 100584,
    endY: 180360,
  },
  {
    cellName: "C1411",
    startX: 100584,
    startY: 171160,
    endX: 106764,
    endY: 180360,
  },
  {
    cellName: "C1412",
    startX: 106764,
    startY: 171160,
    endX: 112944,
    endY: 180360,
  },
  {
    cellName: "C1413",
    startX: 112944,
    startY: 171160,
    endX: 119124,
    endY: 180360,
  },
  {
    cellName: "C1414",
    startX: 119124,
    startY: 171160,
    endX: 125304,
    endY: 180360,
  },
  {
    cellName: "C1415",
    startX: 125304,
    startY: 171160,
    endX: 131484,
    endY: 180360,
  },
  {
    cellName: "C1416",
    startX: 131484,
    startY: 171160,
    endX: 137664,
    endY: 180360,
  },
  {
    cellName: "C1417",
    startX: 137664,
    startY: 171160,
    endX: 143844,
    endY: 180360,
  },
  {
    cellName: "C1418",
    startX: 143844,
    startY: 171160,
    endX: 150024,
    endY: 180360,
  },
  {
    cellName: "C1419",
    startX: 150024,
    startY: 171160,
    endX: 156204,
    endY: 180360,
  },
  {
    cellName: "C1420",
    startX: 156204,
    startY: 171160,
    endX: 162384,
    endY: 180360,
  },
  {
    cellName: "C1421",
    startX: 162384,
    startY: 171160,
    endX: 168564,
    endY: 180360,
  },
  {
    cellName: "C1422",
    startX: 168564,
    startY: 171160,
    endX: 174744,
    endY: 180360,
  },
  {
    cellName: "C1423",
    startX: 174744,
    startY: 171160,
    endX: 180924,
    endY: 180360,
  },
  {
    cellName: "C1424",
    startX: 180924,
    startY: 171160,
    endX: 187104,
    endY: 180360,
  },
  {
    cellName: "C1425",
    startX: 187104,
    startY: 171160,
    endX: 193284,
    endY: 180360,
  },
  {
    cellName: "C1426",
    startX: 193284,
    startY: 171160,
    endX: 199464,
    endY: 180360,
  },
  {
    cellName: "C1427",
    startX: 199464,
    startY: 171160,
    endX: 205644,
    endY: 180360,
  },
  {
    cellName: "C1428",
    startX: 205644,
    startY: 171160,
    endX: 211824,
    endY: 180360,
  },
  {
    cellName: "C1429",
    startX: 211824,
    startY: 171160,
    endX: 218004,
    endY: 180360,
  },
  {
    cellName: "C1430",
    startX: 218004,
    startY: 171160,
    endX: 224184,
    endY: 180360,
  },
  {
    cellName: "C1431",
    startX: 224184,
    startY: 171160,
    endX: 230364,
    endY: 180360,
  },
  {
    cellName: "C1432",
    startX: 230364,
    startY: 171160,
    endX: 236544,
    endY: 180360,
  },
  {
    cellName: "C1501",
    startX: 38784,
    startY: 180360,
    endX: 44964,
    endY: 189560,
  },
  {
    cellName: "C1502",
    startX: 44964,
    startY: 180360,
    endX: 51144,
    endY: 189560,
  },
  {
    cellName: "C1503",
    startX: 51144,
    startY: 180360,
    endX: 57324,
    endY: 189560,
  },
  {
    cellName: "C1504",
    startX: 57324,
    startY: 180360,
    endX: 63504,
    endY: 189560,
  },
  {
    cellName: "C1505",
    startX: 63504,
    startY: 180360,
    endX: 69684,
    endY: 189560,
  },
  {
    cellName: "C1506",
    startX: 69684,
    startY: 180360,
    endX: 75864,
    endY: 189560,
  },
  {
    cellName: "C1507",
    startX: 75864,
    startY: 180360,
    endX: 82044,
    endY: 189560,
  },
  {
    cellName: "C1508",
    startX: 82044,
    startY: 180360,
    endX: 88224,
    endY: 189560,
  },
  {
    cellName: "C1509",
    startX: 88224,
    startY: 180360,
    endX: 94404,
    endY: 189560,
  },
  {
    cellName: "C1510",
    startX: 94404,
    startY: 180360,
    endX: 100584,
    endY: 189560,
  },
  {
    cellName: "C1511",
    startX: 100584,
    startY: 180360,
    endX: 106764,
    endY: 189560,
  },
  {
    cellName: "C1512",
    startX: 106764,
    startY: 180360,
    endX: 112944,
    endY: 189560,
  },
  {
    cellName: "C1513",
    startX: 112944,
    startY: 180360,
    endX: 119124,
    endY: 189560,
  },
  {
    cellName: "C1514",
    startX: 119124,
    startY: 180360,
    endX: 125304,
    endY: 189560,
  },
  {
    cellName: "C1515",
    startX: 125304,
    startY: 180360,
    endX: 131484,
    endY: 189560,
  },
  {
    cellName: "C1516",
    startX: 131484,
    startY: 180360,
    endX: 137664,
    endY: 189560,
  },
  {
    cellName: "C1517",
    startX: 137664,
    startY: 180360,
    endX: 143844,
    endY: 189560,
  },
  {
    cellName: "C1518",
    startX: 143844,
    startY: 180360,
    endX: 150024,
    endY: 189560,
  },
  {
    cellName: "C1519",
    startX: 150024,
    startY: 180360,
    endX: 156204,
    endY: 189560,
  },
  {
    cellName: "C1520",
    startX: 156204,
    startY: 180360,
    endX: 162384,
    endY: 189560,
  },
  {
    cellName: "C1521",
    startX: 162384,
    startY: 180360,
    endX: 168564,
    endY: 189560,
  },
  {
    cellName: "C1522",
    startX: 168564,
    startY: 180360,
    endX: 174744,
    endY: 189560,
  },
  {
    cellName: "C1523",
    startX: 174744,
    startY: 180360,
    endX: 180924,
    endY: 189560,
  },
  {
    cellName: "C1524",
    startX: 180924,
    startY: 180360,
    endX: 187104,
    endY: 189560,
  },
  {
    cellName: "C1525",
    startX: 187104,
    startY: 180360,
    endX: 193284,
    endY: 189560,
  },
  {
    cellName: "C1526",
    startX: 193284,
    startY: 180360,
    endX: 199464,
    endY: 189560,
  },
  {
    cellName: "C1527",
    startX: 199464,
    startY: 180360,
    endX: 205644,
    endY: 189560,
  },
  {
    cellName: "C1528",
    startX: 205644,
    startY: 180360,
    endX: 211824,
    endY: 189560,
  },
  {
    cellName: "C1529",
    startX: 211824,
    startY: 180360,
    endX: 218004,
    endY: 189560,
  },
  {
    cellName: "C1530",
    startX: 218004,
    startY: 180360,
    endX: 224184,
    endY: 189560,
  },
  {
    cellName: "C1531",
    startX: 224184,
    startY: 180360,
    endX: 230364,
    endY: 189560,
  },
  {
    cellName: "C1532",
    startX: 230364,
    startY: 180360,
    endX: 236544,
    endY: 189560,
  },
  {
    cellName: "C1601",
    startX: 38784,
    startY: 189560,
    endX: 44964,
    endY: 198760,
  },
  {
    cellName: "C1602",
    startX: 44964,
    startY: 189560,
    endX: 51144,
    endY: 198760,
  },
  {
    cellName: "C1603",
    startX: 51144,
    startY: 189560,
    endX: 57324,
    endY: 198760,
  },
  {
    cellName: "C1604",
    startX: 57324,
    startY: 189560,
    endX: 63504,
    endY: 198760,
  },
  {
    cellName: "C1605",
    startX: 63504,
    startY: 189560,
    endX: 69684,
    endY: 198760,
  },
  {
    cellName: "C1606",
    startX: 69684,
    startY: 189560,
    endX: 75864,
    endY: 198760,
  },
  {
    cellName: "C1607",
    startX: 75864,
    startY: 189560,
    endX: 82044,
    endY: 198760,
  },
  {
    cellName: "C1608",
    startX: 82044,
    startY: 189560,
    endX: 88224,
    endY: 198760,
  },
  {
    cellName: "C1609",
    startX: 88224,
    startY: 189560,
    endX: 94404,
    endY: 198760,
  },
  {
    cellName: "C1610",
    startX: 94404,
    startY: 189560,
    endX: 100584,
    endY: 198760,
  },
  {
    cellName: "C1611",
    startX: 100584,
    startY: 189560,
    endX: 106764,
    endY: 198760,
  },
  {
    cellName: "C1612",
    startX: 106764,
    startY: 189560,
    endX: 112944,
    endY: 198760,
  },
  {
    cellName: "C1613",
    startX: 112944,
    startY: 189560,
    endX: 119124,
    endY: 198760,
  },
  {
    cellName: "C1614",
    startX: 119124,
    startY: 189560,
    endX: 125304,
    endY: 198760,
  },
  {
    cellName: "C1615",
    startX: 125304,
    startY: 189560,
    endX: 131484,
    endY: 198760,
  },
  {
    cellName: "C1616",
    startX: 131484,
    startY: 189560,
    endX: 137664,
    endY: 198760,
  },
  {
    cellName: "C1617",
    startX: 137664,
    startY: 189560,
    endX: 143844,
    endY: 198760,
  },
  {
    cellName: "C1618",
    startX: 143844,
    startY: 189560,
    endX: 150024,
    endY: 198760,
  },
  {
    cellName: "C1619",
    startX: 150024,
    startY: 189560,
    endX: 156204,
    endY: 198760,
  },
  {
    cellName: "C1620",
    startX: 156204,
    startY: 189560,
    endX: 162384,
    endY: 198760,
  },
  {
    cellName: "C1621",
    startX: 162384,
    startY: 189560,
    endX: 168564,
    endY: 198760,
  },
  {
    cellName: "C1622",
    startX: 168564,
    startY: 189560,
    endX: 174744,
    endY: 198760,
  },
  {
    cellName: "C1623",
    startX: 174744,
    startY: 189560,
    endX: 180924,
    endY: 198760,
  },
  {
    cellName: "C1624",
    startX: 180924,
    startY: 189560,
    endX: 187104,
    endY: 198760,
  },
  {
    cellName: "C1625",
    startX: 187104,
    startY: 189560,
    endX: 193284,
    endY: 198760,
  },
  {
    cellName: "C1626",
    startX: 193284,
    startY: 189560,
    endX: 199464,
    endY: 198760,
  },
  {
    cellName: "C1627",
    startX: 199464,
    startY: 189560,
    endX: 205644,
    endY: 198760,
  },
  {
    cellName: "C1628",
    startX: 205644,
    startY: 189560,
    endX: 211824,
    endY: 198760,
  },
  {
    cellName: "C1629",
    startX: 211824,
    startY: 189560,
    endX: 218004,
    endY: 198760,
  },
  {
    cellName: "C1630",
    startX: 218004,
    startY: 189560,
    endX: 224184,
    endY: 198760,
  },
  {
    cellName: "C1631",
    startX: 224184,
    startY: 189560,
    endX: 230364,
    endY: 198760,
  },
  {
    cellName: "C1632",
    startX: 230364,
    startY: 189560,
    endX: 236544,
    endY: 198760,
  },
];

export const realDataLevel2 = {
  statement_id: "01efb22d-4979-1148-9c63-bac3505ca9e4",
  status: {
    state: "SUCCEEDED",
  },
  manifest: {
    format: "JSON_ARRAY",
    schema: {
      column_count: 3,
      columns: [
        {
          name: "boxName",
          type_text: "STRING",
          type_name: "STRING",
          position: 0,
        },
        {
          name: "navQ",
          type_text: "DOUBLE",
          type_name: "DOUBLE",
          position: 1,
        },
        {
          name: "noOfRecords",
          type_text: "BIGINT",
          type_name: "LONG",
          position: 2,
        },
      ],
    },
    total_chunk_count: 1,
    chunks: [
      {
        chunk_index: 0,
        row_offset: 0,
        row_count: 512,
      },
    ],
    total_row_count: 512,
    truncated: false,
  },
  result: {
    chunk_index: 0,
    row_offset: 0,
    row_count: 512,
    data_array: [
      ["C1228", "95.83441202475686", "28275"],
      ["C1429", "98.40187085641631", "22022"],
      ["C1516", "91.44075378054588", "575446"],
      ["C1602", null, "0"],
      ["C0114", null, "0"],
      ["C1007", "92.02524808108222", "188648"],
      ["C1009", "89.86182837207973", "34971"],
      ["C1203", "95.13015916450038", "21927"],
      ["C1628", "48.97211940095765", "29447"],
      ["C0104", null, "0"],
      ["C0118", null, "0"],
      ["C0312", null, "0"],
      ["C0320", null, "0"],
      ["C0617", "95.01150460305479", "412009"],
      ["C0627", "98.78746965733963", "425144"],
      ["C0729", "99.70329162656401", "25975"],
      ["C0824", "0.10097385955920041", "1951"],
      ["C0828", "99.21695809048016", "36030"],
      ["C1123", "88.1619937694704", "6099"],
      ["C1209", "89.63865993086945", "11283"],
      ["C1214", "90.07366752042932", "8199"],
      ["C1222", "84.07582938388626", "211"],
      ["C1307", "91.63503786575947", "159907"],
      ["C1420", "93.22272727272727", "440"],
      ["C1519", "95.37329251803067", "411937"],
      ["C0313", null, "0"],
      ["C0325", null, "0"],
      ["C0328", null, "0"],
      ["C0510", "99.55743321519178", "1540807"],
      ["C0525", "99.61372490260713", "20022"],
      ["C0829", "99.65760368663595", "19530"],
      ["C1002", "98.97554763117677", "3926"],
      ["C1020", "91.24825436408977", "8020"],
      ["C1119", "94.51054594363269", "16357"],
      ["C1309", "91.31275359140257", "18238"],
      ["C1514", "96.8079091135884", "356753"],
      ["C0405", "97.29792746113989", "10422"],
      ["C0605", "97.0", "4"],
      ["C0613", "98.19124647379294", "386747"],
      ["C0722", "94.53506097560975", "656"],
      ["C1113", "97.32297637542187", "18667"],
      ["C1401", "99.59150199172069", "12803"],
      ["C1432", "99.72151898734177", "395"],
      ["C1620", "47.93811925779139", "44408"],
      ["C0215", null, "0"],
      ["C0415", null, "0"],
      ["C0725", "47.36859056944087", "7762"],
      ["C1012", "96.41098443323833", "18244"],
      ["C1024", "0.9174311926605505", "109"],
      ["C1102", "96.41539232890449", "11967"],
      ["C1111", "99.3469009272816", "10245"],
      ["C1303", "95.0329689101469", "11708"],
      ["C1407", "91.09156375582998", "204546"],
      ["C1411", "96.9575402635432", "15026"],
      ["C1508", "92.92103302574581", "375828"],
      ["C0222", null, "0"],
      ["C0327", null, "0"],
      ["C1019", "92.50328632069012", "14606"],
      ["C1204", "89.10547748323219", "12524"],
      ["C1205", "89.14886265476534", "10419"],
      ["C1624", "47.24525729864819", "61621"],
      ["C0217", null, "0"],
      ["C0406", "98.57909518894674", "260629"],
      ["C0825", "0.011467889908256881", "8720"],
      ["C1416", "93.91203048680777", "281302"],
      ["C1632", null, "0"],
      ["C0315", null, "0"],
      ["C0618", "97.97267475292176", "1039954"],
      ["C0908", "93.59434874748682", "368060"],
      ["C0915", "87.21499630025487", "12163"],
      ["C0932", null, "0"],
      ["C1528", "92.73626515936377", "131272"],
      ["C0119", null, "0"],
      ["C0201", null, "0"],
      ["C0311", null, "0"],
      ["C0530", null, "0"],
      ["C0610", "97.63806486995774", "284446"],
      ["C0807", "98.27031640485541", "89632"],
      ["C0822", "83.2144578313253", "830"],
      ["C1028", "94.52267364907637", "34754"],
      ["C1116", "97.18723350143317", "872894"],
      ["C1117", "96.8453086693069", "243918"],
      ["C1206", "88.85744103527448", "14373"],
      ["C1318", "93.70054436275885", "17819"],
      ["C1414", "98.81048429456875", "9519"],
      ["C1603", null, "0"],
      ["C0324", null, "0"],
      ["C0432", null, "0"],
      ["C0602", "90.33333333333333", "3"],
      ["C0624", "98.11439209044237", "265097"],
      ["C0821", "89.33753637245393", "1031"],
      ["C0913", "90.00015625", "6400"],
      ["C1130", "98.36660789366609", "7373"],
      ["C1210", "95.75533249686323", "9564"],
      ["C0314", null, "0"],
      ["C0709", "92.6123387405799", "15658"],
      ["C0719", "95.19277108433735", "830"],
      ["C1021", "87.05991902834008", "6175"],
      ["C1125", "98.25146998495829", "7313"],
      ["C1331", "99.74830508474577", "1180"],
      ["C0122", null, "0"],
      ["C0207", null, "0"],
      ["C0228", null, "0"],
      ["C0317", null, "0"],
      ["C0426", null, "0"],
      ["C0527", "99.98687853617432", "16614"],
      ["C0923", "81.94903729153246", "13971"],
      ["C1018", "92.1753233316089", "19330"],
      ["C1129", "96.87690341816634", "9391"],
      ["C1216", "94.08776727695157", "247917"],
      ["C0103", null, "0"],
      ["C0718", "96.13226263580539", "2117"],
      ["C0831", "99.84177215189874", "790"],
      ["C0901", "85.2811944937833", "18016"],
      ["C1310", "94.01887509500887", "15788"],
      ["C1505", "89.88711977186311", "4208"],
      ["C0422", null, "0"],
      ["C0928", "92.98996808025535", "10965"],
      ["C1128", "96.32759910246821", "18718"],
      ["C1316", "90.71101702369243", "250122"],
      ["C0108", null, "0"],
      ["C0127", null, "0"],
      ["C0316", null, "0"],
      ["C0531", null, "0"],
      ["C0703", "91.91361741547013", "4318"],
      ["C0717", "95.221664527019", "336504"],
      ["C1314", "88.97658762057878", "19904"],
      ["C1403", null, "0"],
      ["C1530", "97.61964406040667", "50458"],
      ["C1627", "99.62392269522068", "3829"],
      ["C0727", "98.80023724052998", "1058841"],
      ["C0813", "93.93726295828066", "25312"],
      ["C1004", "95.0021365519051", "53357"],
      ["C1114", "92.18204471982759", "14848"],
      ["C0124", null, "0"],
      ["C0229", null, "0"],
      ["C0331", null, "0"],
      ["C0407", "99.24017214476267", "39734"],
      ["C0622", "94.02842584449847", "309296"],
      ["C0806", "97.03974121996303", "3246"],
      ["C0815", "90.6498749119526", "41171"],
      ["C0826", "31.822324390385848", "17183"],
      ["C1510", "97.86867343633904", "262445"],
      ["C0427", null, "0"],
      ["C0502", null, "0"],
      ["C0704", "95.2966915688367", "937"],
      ["C1404", "88.47240618101546", "906"],
      ["C1409", "91.01522017956391", "11695"],
      ["C0326", null, "0"],
      ["C0512", "95.59070562383006", "12287"],
      ["C0524", "97.18664627576379", "28771"],
      ["C0625", "98.99117327993653", "209251"],
      ["C1217", "97.5101492159507", "229131"],
      ["C1512", "98.80643841285637", "215053"],
      ["C1622", "44.67091797602612", "45633"],
      ["C0126", null, "0"],
      ["C0132", null, "0"],
      ["C0518", "96.87074063644044", "17755"],
      ["C0616", "94.7430550381089", "603927"],
      ["C0916", "88.58361094099463", "445773"],
      ["C1325", "91.90780767534186", "9068"],
      ["C1418", "95.2838445807771", "17115"],
      ["C1601", "99.60748476936466", "1149"],
      ["C1611", "74.75147492625369", "2712"],
      ["C0508", "99.48318720990201", "19390"],
      ["C0716", "93.48931254774864", "545827"],
      ["C0720", "93.61458333333333", "672"],
      ["C0808", "98.2791520845254", "375745"],
      ["C1006", "92.65225122054126", "33182"],
      ["C1030", "96.57577907870616", "16911"],
      ["C1202", "96.75284211905776", "30523"],
      ["C1231", "98.78428761651132", "1502"],
      ["C1515", "92.74483667278423", "343819"],
      ["C1612", "41.947307609630265", "37216"],
      ["C0414", null, "0"],
      ["C0603", "100.0", "1"],
      ["C0706", "97.88187996232001", "59448"],
      ["C0904", "95.99325217391305", "28750"],
      ["C1101", "86.49988808058198", "17870"],
      ["C1317", "93.04098182759168", "239594"],
      ["C0231", null, "0"],
      ["C0612", "96.35764171935932", "136167"],
      ["C0809", "96.42210846707249", "14456"],
      ["C0812", "93.08229114557278", "3998"],
      ["C1124", "94.09281875915974", "4094"],
      ["C0226", null, "0"],
      ["C0501", null, "0"],
      ["C0504", "100.0", "2"],
      ["C0509", "96.75", "4"],
      ["C0528", null, "0"],
      ["C1105", "92.38704458681163", "15453"],
      ["C1112", "99.90425289970435", "4397"],
      ["C0225", null, "0"],
      ["C0304", null, "0"],
      ["C0323", null, "0"],
      ["C0410", "99.0", "1"],
      ["C0532", null, "0"],
      ["C1301", "97.60360405021875", "645496"],
      ["C1305", "88.45755576345462", "40618"],
      ["C1319", "90.3045594870577", "8422"],
      ["C0106", null, "0"],
      ["C0115", null, "0"],
      ["C0216", null, "0"],
      ["C0412", null, "0"],
      ["C0519", "97.34313132787148", "287814"],
      ["C0730", "99.81092928112216", "17110"],
      ["C0827", "95.87933656407746", "802911"],
      ["C0832", null, "0"],
      ["C0918", "87.8739981360671", "26825"],
      ["C1031", "97.16388888888889", "1080"],
      ["C1115", "91.63735732249951", "20676"],
      ["C1225", "96.77402597402597", "1540"],
      ["C1518", "98.86708469600597", "465996"],
      ["C0232", null, "0"],
      ["C0322", null, "0"],
      ["C0423", null, "0"],
      ["C0506", "98.76151774774397", "429294"],
      ["C0529", null, "0"],
      ["C0914", "86.86004721930745", "7624"],
      ["C1224", null, "0"],
      ["C1232", null, "0"],
      ["C1322", "83.30244870946393", "3022"],
      ["C1425", "96.24266687807199", "12614"],
      ["C1430", "99.19492502883506", "17340"],
      ["C1529", "96.01131249056034", "66210"],
      ["C1626", "46.65758050632353", "45307"],
      ["C0129", null, "0"],
      ["C0421", null, "0"],
      ["C0522", "92.2173646578141", "14685"],
      ["C0601", "97.0", "1"],
      ["C0609", "98.5882382905025", "157052"],
      ["C0628", "99.60671722643553", "923"],
      ["C0714", "91.96527935713745", "13191"],
      ["C1201", "90.17376520085298", "17351"],
      ["C1419", "95.00680497925312", "6025"],
      ["C1520", "93.15186288165326", "343178"],
      ["C1532", "99.96669236395263", "101178"],
      ["C0925", null, "0"],
      ["C1003", "95.5178618682191", "3779"],
      ["C1109", "90.17240143369176", "16740"],
      ["C1126", "99.27894647792003", "17731"],
      ["C1610", "46.66080213065956", "63830"],
      ["C0101", null, "0"],
      ["C0411", null, "0"],
      ["C0417", null, "0"],
      ["C0517", "95.46705426356588", "2580"],
      ["C0801", "87.3734950232538", "23007"],
      ["C0819", "93.21284829721363", "1292"],
      ["C0907", "93.9040119615046", "131756"],
      ["C0917", "88.92382713973431", "243827"],
      ["C1426", "97.81465772088121", "38901"],
      ["C1616", "48.807898042711834", "49354"],
      ["C0128", null, "0"],
      ["C0204", null, "0"],
      ["C0219", null, "0"],
      ["C0404", "100.0", "1"],
      ["C0430", null, "0"],
      ["C0503", "88.0", "1"],
      ["C0523", "96.79913072183099", "54528"],
      ["C1011", "94.8291337528451", "37345"],
      ["C1015", "89.15421809390507", "7859"],
      ["C1220", "91.39095696386417", "5507"],
      ["C1332", null, "0"],
      ["C0820", "94.0467882632831", "1261"],
      ["C0911", "92.11599625818522", "6414"],
      ["C1621", "49.12770160353242", "60242"],
      ["C1629", "53.2036997433704", "9352"],
      ["C0117", null, "0"],
      ["C0120", null, "0"],
      ["C0409", "100.0", "1"],
      ["C0505", "97.65949404614852", "40478"],
      ["C0607", "97.76502246671217", "218323"],
      ["C0620", "95.17120072743805", "549875"],
      ["C0731", "99.8413407821229", "895"],
      ["C0823", "85.58129175946547", "449"],
      ["C0927", "90.27383035193583", "336084"],
      ["C1025", "0.0", "3633"],
      ["C1106", "91.57225375958569", "20082"],
      ["C1423", "90.42520621827411", "12608"],
      ["C0131", null, "0"],
      ["C0310", null, "0"],
      ["C0702", "91.53253980522491", "6469"],
      ["C0929", "94.42594594594594", "5550"],
      ["C1207", "88.21746851654216", "117125"],
      ["C1211", "97.79359035379977", "12606"],
      ["C1215", "91.88462721772126", "9751"],
      ["C1226", "98.53732935153583", "9376"],
      ["C1320", "89.20059798858385", "3679"],
      ["C1405", "88.9701291192908", "5189"],
      ["C1526", "98.53327384748793", "318283"],
      ["C1615", "42.47671941138036", "35201"],
      ["C0218", null, "0"],
      ["C0713", "92.95553545858769", "13269"],
      ["C0926", "69.0", "40"],
      ["C1029", "95.22791718240126", "17774"],
      ["C1227", "94.5534004848909", "240054"],
      ["C1313", "90.67377418317173", "21761"],
      ["C1323", "85.93330452319216", "6942"],
      ["C1513", "98.67065930838145", "354068"],
      ["C0112", null, "0"],
      ["C0214", null, "0"],
      ["C0321", null, "0"],
      ["C0420", null, "0"],
      ["C0428", null, "0"],
      ["C0811", "92.1330538085255", "7155"],
      ["C1509", "94.58147376423715", "426174"],
      ["C1522", "97.46429918742028", "333998"],
      ["C1631", "96.66666666666667", "102"],
      ["C0130", null, "0"],
      ["C0309", null, "0"],
      ["C0619", "97.23693994251846", "670824"],
      ["C0630", "99.52816901408451", "284"],
      ["C0701", "90.56619756797377", "7319"],
      ["C0105", null, "0"],
      ["C0223", null, "0"],
      ["C0425", null, "0"],
      ["C0431", null, "0"],
      ["C0621", "92.7371947477824", "622518"],
      ["C0710", "84.84358846089711", "11994"],
      ["C0723", "95.90324763193505", "1478"],
      ["C0931", "94.45454545454545", "418"],
      ["C1008", "91.53490174002694", "316202"],
      ["C1107", "90.53693201757464", "142478"],
      ["C1108", "89.47024707881273", "526755"],
      ["C1221", "85.45411255411256", "4620"],
      ["C1311", "93.86418388350995", "26234"],
      ["C1504", null, "0"],
      ["C1613", "92.06530612244897", "735"],
      ["C1618", "44.10642714909122", "47206"],
      ["C0212", null, "0"],
      ["C0306", "94.15490625978791", "22349"],
      ["C0804", "94.53507825148408", "3706"],
      ["C0810", "94.03466035634744", "7184"],
      ["C1118", "97.73612786489747", "19896"],
      ["C1302", "98.49364435429344", "14790"],
      ["C1327", "94.54388413993026", "336682"],
      ["C0107", null, "0"],
      ["C0930", "93.71465325692901", "8551"],
      ["C1005", "93.58976312738115", "30185"],
      ["C1016", "91.02612584203823", "402743"],
      ["C0511", null, "0"],
      ["C0515", "93.45988198269079", "127100"],
      ["C0728", "99.48612573217804", "74094"],
      ["C0903", "98.68187134502924", "2565"],
      ["C1001", "86.37519075648572", "13761"],
      ["C1127", "92.74071685248319", "206207"],
      ["C1328", "95.29179970407289", "51364"],
      ["C0203", null, "0"],
      ["C0220", null, "0"],
      ["C0614", "97.32157001326992", "970616"],
      ["C0708", "95.19082634981115", "363236"],
      ["C1421", null, "0"],
      ["C1507", "92.2615119391474", "132254"],
      ["C1609", "48.29900514778183", "34578"],
      ["C0224", null, "0"],
      ["C0227", null, "0"],
      ["C0230", null, "0"],
      ["C0418", null, "0"],
      ["C0604", "92.6", "5"],
      ["C1013", "90.47797173732336", "4812"],
      ["C1110", "97.11282051282052", "10920"],
      ["C1121", "87.13931373325937", "12619"],
      ["C1422", "86.09559071019859", "5942"],
      ["C1604", null, "0"],
      ["C1607", "55.94106090373281", "2036"],
      ["C1630", "71.07159394479073", "5615"],
      ["C0330", null, "0"],
      ["C0707", "97.34123199688595", "164416"],
      ["C1218", "97.3930606900801", "25968"],
      ["C1306", "92.35976207374362", "29589"],
      ["C0208", null, "0"],
      ["C0210", null, "0"],
      ["C0302", null, "0"],
      ["C0520", "97.11271239213593", "22482"],
      ["C1229", "96.74687677455991", "14088"],
      ["C1527", "98.41068537506746", "339099"],
      ["C0121", null, "0"],
      ["C0305", null, "0"],
      ["C0318", null, "0"],
      ["C0429", null, "0"],
      ["C0608", "98.17955873606753", "415307"],
      ["C1308", "92.477719916334", "324146"],
      ["C1330", "99.19500346981263", "20174"],
      ["C1410", "94.0577832613613", "9726"],
      ["C1617", "47.64092282659228", "51624"],
      ["C0110", null, "0"],
      ["C0202", null, "0"],
      ["C0211", null, "0"],
      ["C0329", null, "0"],
      ["C0416", null, "0"],
      ["C0623", "97.7014553580472", "631872"],
      ["C0632", null, "0"],
      ["C0724", "50.91661382371591", "3154"],
      ["C0816", "92.16663603104674", "505947"],
      ["C1104", "93.48239600665558", "30050"],
      ["C1424", "92.81896089983931", "9335"],
      ["C1517", "96.98389614868391", "429152"],
      ["C0206", null, "0"],
      ["C0209", null, "0"],
      ["C0514", "96.85714285714286", "7"],
      ["C0606", "98.44841980997894", "140616"],
      ["C0629", "99.214463840399", "401"],
      ["C0711", "92.62777490965411", "7748"],
      ["C0732", null, "0"],
      ["C0920", "88.22924012158055", "16450"],
      ["C1010", "94.05858421480879", "30725"],
      ["C1022", "83.36426413465688", "6951"],
      ["C1120", "93.12677374083893", "12826"],
      ["C1230", "97.86852848943849", "16901"],
      ["C1315", "88.653429486143", "30923"],
      ["C1524", "98.03781349842384", "281381"],
      ["C0109", null, "0"],
      ["C0402", "99.5", "2"],
      ["C0721", "89.53091684434968", "469"],
      ["C0817", "93.31896211628478", "375148"],
      ["C1103", "93.84324942791763", "2622"],
      ["C1122", "86.05873320537428", "2605"],
      ["C1208", "87.65832711360127", "195614"],
      ["C1223", null, "0"],
      ["C1321", "87.6470588235294", "306"],
      ["C0308", null, "0"],
      ["C0332", null, "0"],
      ["C0715", "91.10825510356132", "13422"],
      ["C1412", "97.57976137870084", "6789"],
      ["C1427", "96.59320003362852", "428208"],
      ["C1501", "99.73219259848786", "7539"],
      ["C1521", "94.91402870156848", "367297"],
      ["C0424", null, "0"],
      ["C0521", "61.0", "3"],
      ["C0615", "93.5430056016157", "367394"],
      ["C0726", "76.04454226665672", "13403"],
      ["C1326", "93.31596509240246", "23376"],
      ["C1408", "92.88445591981552", "1043394"],
      ["C0307", "95.225", "80"],
      ["C1213", "96.30752619371351", "12503"],
      ["C1428", "97.47011399908801", "54825"],
      ["C1623", "44.82323259149094", "37654"],
      ["C0513", null, "0"],
      ["C1131", "97.07063197026022", "538"],
      ["C1312", "93.87542298533651", "15958"],
      ["C1402", null, "0"],
      ["C0116", null, "0"],
      ["C0303", "95.0", "1"],
      ["C0818", "93.79408374951979", "2603"],
      ["C0921", "86.17795125059742", "12554"],
      ["C1023", "85.96972939917444", "6541"],
      ["C1219", "94.25393820054529", "13204"],
      ["C1304", "90.75583029746709", "34861"],
      ["C0919", "87.79374471682164", "21294"],
      ["C1324", "88.1105148569501", "32611"],
      ["C1413", "99.58942128189172", "8035"],
      ["C1506", "89.97023180154534", "24590"],
      ["C1511", "99.30653554723234", "231748"],
      ["C1625", "48.21348612307081", "75485"],
      ["C0125", null, "0"],
      ["C0408", "94.02239382239382", "1295"],
      ["C0712", "93.75880348352896", "2641"],
      ["C0814", "91.71691664454472", "30136"],
      ["C0830", "99.79790989973168", "14162"],
      ["C1017", "89.98796245919478", "205856"],
      ["C1605", null, "0"],
      ["C0113", null, "0"],
      ["C0213", null, "0"],
      ["C0301", null, "0"],
      ["C0516", "93.0", "4"],
      ["C0906", "92.43919836956522", "20608"],
      ["C1329", "97.78434846037618", "19193"],
      ["C1417", "95.85734616091243", "342907"],
      ["C1502", "98.84848484848484", "99"],
      ["C1525", "98.5541797686792", "367628"],
      ["C0111", null, "0"],
      ["C0205", null, "0"],
      ["C0319", null, "0"],
      ["C0526", null, "0"],
      ["C0705", "93.55656108597285", "221"],
      ["C0802", "90.56164246369555", "9985"],
      ["C0803", "93.01269140571114", "7249"],
      ["C1014", "86.7276730269843", "8857"],
      ["C1132", null, "0"],
      ["C1523", "98.08995216964229", "405182"],
      ["C1608", "98.91566265060241", "996"],
      ["C0403", "96.0", "2"],
      ["C0922", "80.36014123185564", "10196"],
      ["C1027", "88.9756224268868", "333011"],
      ["C1032", null, "0"],
      ["C1212", "98.93603971962617", "6848"],
      ["C1431", "97.9973474801061", "377"],
      ["C1531", "99.33409945674885", "9572"],
      ["C1614", "44.58213322238179", "74507"],
      ["C0221", null, "0"],
      ["C0413", null, "0"],
      ["C0419", null, "0"],
      ["C0631", "100.0", "28"],
      ["C0912", "92.36012861736334", "2799"],
      ["C1026", "29.171151885830785", "9810"],
      ["C1503", null, "0"],
      ["C1606", null, "0"],
      ["C0626", "99.10568982591687", "244366"],
      ["C0910", "97.07537903053598", "4683"],
      ["C1619", "47.98386592876908", "44812"],
      ["C0102", null, "0"],
      ["C0902", "91.11111111111111", "5319"],
      ["C0909", "94.88396551724138", "5800"],
      ["C0123", null, "0"],
      ["C0401", "96.0", "2"],
      ["C0507", "99.13351156907041", "738348"],
      ["C0611", "95.40945164748925", "180942"],
      ["C0805", "94.24285443876585", "5283"],
      ["C0905", "92.72543882630337", "19085"],
      ["C0924", null, "0"],
      ["C1406", "91.99659003023272", "28446"],
      ["C1415", "91.96772841575859", "19088"],
    ],
  },
};

export const realDataLevel3 = {
  statement_id: "01efb26a-d612-16ae-8d28-a2f0613e3374",
  status: { state: "SUCCEEDED" },
  manifest: {
    format: "JSON_ARRAY",
    schema: {
      column_count: 4,
      columns: [
        { name: "lgvId", type_text: "INT", type_name: "INT", position: 0 },
        {
          name: "longitude",
          type_text: "DOUBLE",
          type_name: "DOUBLE",
          position: 1,
        },
        {
          name: "latitude",
          type_text: "DOUBLE",
          type_name: "DOUBLE",
          position: 2,
        },
        { name: "navQ", type_text: "DOUBLE", type_name: "DOUBLE", position: 3 },
      ],
    },
    total_chunk_count: 2,
    chunks: [
      { chunk_index: 0, row_offset: 0, row_count: 186668 },
      { chunk_index: 1, row_offset: 186668, row_count: 180069 },
    ],
    total_row_count: 366737,
    truncated: false,
  },
  result: {
    chunk_index: 0,
    row_offset: 0,
    row_count: 186668,
    next_chunk_index: 1,
    next_chunk_internal_link:
      "/api/2.0/sql/statements/01efb26a-d612-16ae-8d28-a2f0613e3374/result/chunks/1",
    data_array: [
      ["23", "200490.647906918", "119532.65346327491", "96.0"],
      ["23", "184724.24345948442", "119647.49391408285", "0.0"],
      ["23", "204073.5625513106", "124317.26902113302", "94.0"],
      ["23", "201327.42930345473", "120428.45044910657", "100.0"],
      ["23", "204082.51135480963", "119627.46480969305", "100.0"],
      ["23", "204082.37727565356", "119625.88725868544", "100.0"],
      ["23", "204082.14896478623", "119627.10851029646", "100.0"],
      ["23", "204082.0531504756", "119629.45816417402", "100.0"],
      ["23", "204076.11190217047", "121900.03380433786", "100.0"],
      ["23", "201323.9190965355", "120580.6537460359", "100.0"],
      ["23", "202701.97420822002", "118382.09634481677", "100.0"],
      ["23", "202701.02473447894", "118259.6780017923", "100.0"],
      ["23", "202497.15923697143", "117196.73923098507", "100.0"],
      ["23", "199906.5426202161", "117116.34395708572", "96.0"],
      ["23", "199216.39421829034", "117115.53215640156", "95.0"],
      ["23", "199215.1065346314", "117115.41979341977", "95.0"],
      ["23", "185476.80693218284", "119668.07226214361", "0.0"],
      ["23", "184032.0685794615", "119692.72861705488", "0.0"],
      ["23", "184031.0866597232", "119692.74565672387", "0.0"],
      ["23", "185447.27621737507", "119662.49089798277", "0.0"],
      ["23", "192028.57848166057", "119518.0406551561", "0.0"],
      ["23", "202037.14574080284", "118515.30935952839", "100.0"],
      ["23", "204083.94208044067", "119624.91952133169", "100.0"],
      ["23", "204083.85987035325", "119624.91952133169", "100.0"],
      ["23", "204084.24926235242", "119626.35907660054", "100.0"],
      ["23", "204082.87617249007", "119627.11808345105", "100.0"],
      ["23", "204083.3670060916", "119627.37128919402", "100.0"],
      ["23", "204083.33404835608", "119628.29984642658", "100.0"],
      ["23", "204082.0220195799", "119626.74408117706", "100.0"],
      ["23", "204082.0019715235", "119628.88030381702", "100.0"],
      ["23", "204081.85539285062", "119627.44813673766", "100.0"],
      ["23", "204082.19545883985", "119627.65452208118", "100.0"],
      ["23", "204082.13823861434", "119626.18909871913", "100.0"],
      ["23", "204081.9656738577", "119627.68026584508", "100.0"],
      ["23", "204081.77918785316", "119626.43043580881", "100.0"],
      ["23", "201330.03376879898", "122080.78657910143", "100.0"],
      ["23", "204081.3979206629", "119627.32365635266", "100.0"],
      ["23", "204081.9651865614", "119627.52955401411", "100.0"],
      ["23", "204081.8411763775", "119626.2879124603", "100.0"],
      ["23", "204082.48288930373", "119626.73311059804", "94.0"],
      ["23", "204083.3950765761", "119627.79090958944", "100.0"],
      ["23", "204082.4335778257", "119627.02229485955", "100.0"],
      ["23", "204082.29505450255", "119628.20080887325", "100.0"],
      ["23", "204083.4208739896", "119627.88068299636", "97.0"],
      ["23", "204084.23164334675", "119628.75907765445", "100.0"],
      ["23", "204084.0847582191", "119628.6033413757", "100.0"],
      ["23", "204083.9108078347", "119627.51026853277", "100.0"],
      ["23", "204084.12449157465", "119628.57363839149", "100.0"],
      ["23", "204084.0183789649", "119627.63341232023", "97.0"],
      ["23", "204084.08434278413", "119628.71807920546", "100.0"],
      ["23", "204084.19898453797", "119627.81629401246", "100.0"],
      ["23", "204080.91245161943", "119615.34821174791", "100.0"],
      ["23", "204084.02409231386", "119627.23537917667", "100.0"],
      ["23", "204084.297774342", "119627.51487687504", "100.0"],
      ["23", "204084.26177542063", "119627.98215920365", "100.0"],
      ["23", "201325.0007275255", "119369.59977628439", "100.0"],
      ["23", "204086.1396884982", "119626.87187172436", "97.0"],
      ["23", "204086.23934134335", "119627.92846196453", "97.0"],
      ["23", "204086.20730474198", "119629.40782060075", "97.0"],
      ["23", "204085.9786738414", "119626.76927809196", "97.0"],
      ["23", "204086.10573053348", "119628.2666567055", "97.0"],
      ["23", "204086.11632046322", "119627.65321946816", "97.0"],
      ["23", "204086.1572936279", "119628.83541942209", "97.0"],
      ["23", "204086.00976788325", "119628.08833563121", "97.0"],
      ["23", "204084.9337892451", "119627.57025653787", "97.0"],
      ["23", "204084.96285249855", "119628.26079607857", "97.0"],
      ["23", "204085.3172573001", "119626.23592918659", "97.0"],
      ["23", "204085.638155514", "119626.03143687015", "97.0"],
      ["23", "204085.4483495114", "119629.06376719096", "97.0"],
      ["23", "204085.37465514164", "119625.3517120086", "97.0"],
      ["23", "204085.3140728554", "119627.16566967523", "97.0"],
      ["23", "204085.5564424076", "119627.27969987791", "97.0"],
      ["23", "204085.23027711225", "119627.54805717633", "97.0"],
      ["23", "204085.6425615137", "119626.9894842489", "97.0"],
      ["23", "204085.6425615137", "119627.02997525684", "97.0"],
      ["23", "204085.39397250477", "119626.58415230931", "97.0"],
      ["23", "204085.51166214878", "119627.60747566674", "97.0"],
      ["23", "204085.1324444199", "119626.90994746372", "97.0"],
      ["23", "204082.69206782474", "119626.19594577204", "100.0"],
      ["23", "204083.35770163624", "119626.98836178806", "100.0"],
      ["23", "204083.2667201166", "119626.59402375431", "100.0"],
      ["23", "204083.30800097203", "119626.21489407569", "100.0"],
      ["23", "204083.32836927165", "119626.80669525861", "100.0"],
      ["23", "204083.6818474984", "119626.30262734197", "100.0"],
      ["23", "204083.27362338753", "119627.62336659644", "100.0"],
      ["23", "204083.2003945631", "119626.28202923441", "100.0"],
      ["23", "204083.3696748458", "119624.73311077539", "100.0"],
      ["23", "204083.21021701486", "119627.29032204192", "100.0"],
      ["23", "204083.26842893622", "119626.00362230616", "100.0"],
      ["23", "201328.1799380174", "120535.78467416846", "100.0"],
      ["23", "201328.1799380174", "118775.909503777", "100.0"],
      ["23", "198044.77331136607", "119533.36180533252", "94.0"],
      ["23", "196672.61157783706", "119526.97531257084", "0.0"],
      ["23", "185567.5903842924", "119682.23717644997", "0.0"],
      ["23", "204076.24029415462", "116886.45525926366", "100.0"],
      ["23", "204076.22179806768", "124688.98329561013", "100.0"],
      ["23", "203860.44152710642", "117680.11344870082", "100.0"],
      ["23", "191184.03950875122", "119548.4802655328", "0.0"],
      ["23", "185456.0126573316", "119626.94971943417", "0.0"],
      ["23", "192422.26588555024", "119520.43727263827", "0.0"],
      ["23", "201328.01931388152", "124670.49802194639", "86.0"],
      ["23", "204076.95815187256", "121294.8623856667", "100.0"],
      ["23", "204085.09604813685", "119628.12947331167", "97.0"],
      ["23", "204085.2078223119", "119627.00642309313", "97.0"],
      ["23", "204075.41209677942", "121116.59964311405", "97.0"],
      ["23", "201331.52184319025", "122058.08402498125", "100.0"],
      ["23", "201488.13968542728", "119538.4523611846", "94.0"],
      ["23", "185704.3297577801", "119678.54205570873", "0.0"],
      ["23", "197937.4230243203", "119461.78864316936", "0.0"],
      ["23", "204075.49525406622", "116641.68867341337", "100.0"],
      ["23", "204076.58354273907", "122464.19022646065", "97.0"],
      ["23", "202933.4159981352", "116356.36228539828", "97.0"],
      ["23", "202940.50989707367", "117310.16312099034", "100.0"],
      ["23", "208153.51122077458", "117213.95090037449", "100.0"],
      ["23", "208090.19252229415", "117213.62449839569", "100.0"],
      ["23", "208090.6506494716", "117213.59159517546", "100.0"],
      ["23", "208091.73884874693", "117213.57158490596", "100.0"],
      ["23", "204086.44154687857", "119627.08430979034", "97.0"],
      ["23", "204086.78605623424", "119628.95139876328", "97.0"],
      ["23", "204087.09923964294", "119627.66921881742", "97.0"],
      ["23", "204087.13373221556", "119628.31309267202", "97.0"],
      ["23", "204087.01582456875", "119628.4260323366", "97.0"],
      ["23", "200225.40986803672", "119532.85571023259", "97.0"],
      ["23", "202594.55675627114", "119316.0800124201", "96.0"],
      ["23", "201703.41517306742", "119542.46194968946", "96.0"],
      ["23", "187752.0472162038", "119635.70629080902", "0.0"],
      ["23", "186400.8644230238", "119674.58487770983", "0.0"],
      ["23", "190977.17913158727", "119549.47352269928", "0.0"],
      ["23", "192858.4033059621", "119496.03147139265", "0.0"],
      ["23", "200406.53755144935", "119516.26689767996", "94.0"],
      ["23", "204077.598050529", "122909.25277247306", "94.0"],
      ["23", "201329.76485202654", "122133.4125541207", "100.0"],
      ["23", "204085.5262546775", "119628.21889587512", "97.0"],
      ["23", "204085.5918673417", "119627.03305432056", "97.0"],
      ["23", "204085.9326801455", "119627.48331961413", "97.0"],
      ["23", "204085.9468836711", "119629.05141159444", "97.0"],
      ["23", "204085.8083099192", "119627.44347358185", "97.0"],
      ["23", "204086.07849812668", "119627.9706915154", "97.0"],
      ["23", "204085.92136972077", "119626.63772587173", "97.0"],
      ["23", "204085.7331328871", "119627.4997811413", "97.0"],
      ["23", "204084.6187175023", "119626.8204992252", "100.0"],
      ["23", "204086.00985809235", "119626.75919004796", "97.0"],
      ["23", "202721.89541629353", "118105.62726984783", "100.0"],
      ["23", "204081.94197898774", "119625.25316635864", "100.0"],
      ["23", "204085.55252046898", "119625.76133001444", "97.0"],
      ["23", "201328.01393821236", "123982.78099119649", "100.0"],
      ["23", "201330.18422439517", "122262.5080217922", "100.0"],
      ["23", "201328.1267479219", "121877.6630716029", "100.0"],
      ["23", "204087.4999684663", "119628.34784849986", "97.0"],
      ["23", "204084.67270234492", "119624.86668571222", "94.0"],
      ["23", "204085.8220322926", "119626.31495764469", "97.0"],
      ["23", "204085.90953634813", "119625.42860365468", "97.0"],
      ["23", "204086.0645035949", "119625.79196949585", "97.0"],
      ["23", "204086.02570679653", "119625.47611033666", "97.0"],
      ["23", "204075.97068832637", "119622.91272980181", "100.0"],
      ["23", "204075.9702855025", "119624.4963100022", "100.0"],
      ["23", "204076.0448986525", "119622.83562211602", "100.0"],
      ["23", "204076.0448986525", "119623.74176108156", "100.0"],
      ["23", "204075.8975666382", "119624.02484770132", "100.0"],
      ["23", "204076.1888493584", "119624.54016491755", "100.0"],
      ["23", "201330.3154122664", "122021.71314146329", "100.0"],
      ["23", "201325.1321060734", "119402.50640594117", "100.0"],
      ["23", "204076.42886175917", "119624.64095722926", "100.0"],
      ["23", "204076.29051279504", "119625.26493581539", "100.0"],
      ["23", "204076.36548596804", "119624.62040820128", "100.0"],
      ["23", "204076.29247130523", "119624.54469650041", "100.0"],
      ["23", "204076.44093717862", "119624.31690297309", "100.0"],
      ["23", "204076.3205382003", "119624.15208201013", "100.0"],
      ["23", "202400.85023212293", "122153.99708677868", "100.0"],
      ["23", "201483.48470933476", "122081.48803594298", "93.0"],
      ["23", "195401.56014539723", "122130.41147696032", "0.0"],
      ["23", "188620.46210086258", "122224.9676361255", "0.0"],
      ["23", "193260.26843971296", "122088.72500195437", "0.0"],
      ["23", "195659.95518888", "122054.05133628825", "0.0"],
      ["23", "197586.0264028494", "122036.5332445835", "0.0"],
      ["23", "204077.8922016095", "123068.89815873909", "94.0"],
      ["9", "201316.0", "118737.0", "94.0"],
      ["9", "204076.0", "121589.0", "94.0"],
      ["9", "204081.1", "120550.8", "94.0"],
      ["9", "201313.0", "117068.0", "94.0"],
      ["9", "204090.0", "120164.0", "99.0"],
      ["9", "204083.0", "120547.5", "94.0"],
      ["9", "204083.1", "120549.1", "94.0"],
      ["9", "204078.0", "121283.0", "100.0"],
      ["9", "201311.0", "116435.0", "94.0"],
      ["9", "201320.0", "123128.0", "94.0"],
      ["9", "204074.6", "118151.8", "100.0"],
      ["9", "204074.9", "118151.8", "100.0"],
      ["9", "204077.0", "118689.0", "100.0"],
      ["9", "204079.0", "116996.0", "100.0"],
      ["9", "204081.6", "120551.2", "94.0"],
      ["9", "204081.6", "120550.9", "94.0"],
      ["9", "201317.0", "120237.0", "94.0"],
      ["9", "202702.0", "118589.0", "97.0"],
      ["9", "203260.0", "117207.0", "97.0"],
      ["9", "204078.0", "120549.0", "97.0"],
      ["9", "208894.0", "124421.0", "100.0"],
      ["9", "201320.0", "117800.0", "94.0"],
      ["9", "204082.0", "117870.0", "97.0"],
      ["9", "204081.0", "119003.0", "97.0"],
      ["9", "204084.0", "119790.0", "97.0"],
      ["9", "204025.0", "118916.0", "97.0"],
      ["9", "205013.0", "122022.0", "100.0"],
      ["9", "207486.0", "122019.0", "100.0"],
      ["9", "201327.0", "123711.0", "93.0"],
      ["9", "201323.0", "118770.0", "97.0"],
      ["9", "201324.0", "122147.0", "94.0"],
      ["9", "204081.0", "120475.0", "100.0"],
      ["9", "201317.0", "116341.0", "100.0"],
      ["9", "204085.0", "118014.0", "97.0"],
      ["9", "204088.0", "119639.0", "97.0"],
      ["9", "204091.0", "120688.0", "94.0"],
      ["9", "204090.0", "120736.0", "100.0"],
      ["9", "202706.0", "124104.0", "93.0"],
      ["9", "213567.0", "122015.0", "100.0"],
      ["9", "215296.0", "122019.0", "100.0"],
      ["9", "201325.0", "119595.0", "93.0"],
      ["9", "221681.0", "124414.0", "100.0"],
      ["9", "206713.0", "124426.0", "100.0"],
      ["9", "219040.9", "124416.0", "100.0"],
      ["9", "220538.0", "124413.0", "100.0"],
      ["9", "202632.0", "123367.0", "93.0"],
      ["9", "201329.0", "124562.0", "91.0"],
      ["9", "221540.0", "122000.0", "100.0"],
      ["9", "221591.0", "121999.0", "100.0"],
      ["9", "223016.0", "122011.0", "100.0"],
      ["9", "204078.0", "116954.0", "97.0"],
      ["9", "204076.0", "117340.0", "100.0"],
      ["9", "202812.0", "122047.0", "89.0"],
      ["9", "217876.3", "122013.0", "100.0"],
      ["9", "203657.0", "122026.0", "94.0"],
      ["9", "201320.0", "119880.0", "94.0"],
      ["9", "204084.0", "116630.0", "96.0"],
      ["9", "204078.0", "116562.0", "97.0"],
      ["9", "204080.9", "120549.6", "94.0"],
      ["9", "204078.0", "121494.0", "94.0"],
      ["9", "204078.0", "121166.0", "97.0"],
      ["9", "201319.0", "123602.0", "94.0"],
      ["9", "201319.8", "121092.8", "94.0"],
      ["9", "204076.0", "117885.0", "97.0"],
      ["9", "201316.0", "124499.0", "91.0"],
      ["9", "201325.0", "116782.0", "92.0"],
      ["9", "204083.0", "117696.0", "97.0"],
      ["9", "204083.0", "120208.0", "100.0"],
      ["9", "207288.0", "124412.0", "93.0"],
      ["9", "207288.0", "124416.0", "100.0"],
      ["9", "220586.0", "124409.0", "100.0"],
      ["9", "202129.0", "122770.0", "94.0"],
      ["9", "205085.0", "122008.0", "94.0"],
      ["9", "207163.0", "122024.0", "99.0"],
      ["9", "207070.0", "122022.0", "100.0"],
      ["9", "203379.0", "122020.0", "100.0"],
      ["9", "201320.0", "121099.1", "93.0"],
      ["9", "201319.0", "121099.2", "94.0"],
      ["9", "201318.5", "121099.1", "94.0"],
      ["9", "202700.0", "122481.0", "94.0"],
      ["9", "202703.0", "123738.0", "93.0"],
      ["9", "208870.0", "122020.0", "99.0"],
      ["9", "207017.0", "122029.0", "100.0"],
      ["9", "202701.0", "119451.0", "96.0"],
      ["9", "204082.0", "117968.0", "94.0"],
      ["9", "204081.0", "117258.0", "97.0"],
      ["9", "202170.0", "123039.0", "93.0"],
      ["9", "204235.0", "122014.0", "94.0"],
      ["9", "213813.0", "122010.0", "100.0"],
      ["9", "201312.0", "122167.0", "94.0"],
      ["9", "204082.0", "117418.0", "97.0"],
      ["9", "204082.0", "117180.0", "97.0"],
      ["9", "204083.4", "120550.1", "94.0"],
      ["9", "204083.9", "120550.9", "94.0"],
      ["9", "204085.0", "121402.0", "100.0"],
      ["9", "201320.0", "122004.0", "93.0"],
      ["9", "201324.0", "121451.0", "93.0"],
      ["9", "204081.0", "120552.6", "94.0"],
      ["9", "204081.0", "120557.0", "94.0"],
      ["9", "204079.0", "124057.0", "97.0"],
      ["9", "204076.0", "117560.0", "97.0"],
      ["9", "204078.0", "121540.0", "94.0"],
      ["9", "201319.0", "119832.0", "86.0"],
      ["9", "202697.0", "120341.0", "97.0"],
      ["9", "207209.0", "122025.0", "100.0"],
      ["9", "207158.3", "122025.7", "100.0"],
      ["9", "207159.1", "122026.0", "100.0"],
      ["9", "202797.0", "123027.0", "90.0"],
      ["9", "207749.0", "122024.0", "100.0"],
      ["9", "204081.0", "120876.0", "97.0"],
      ["9", "201896.0", "124324.0", "90.0"],
      ["9", "202699.0", "120341.0", "97.0"],
      ["9", "202208.0", "121083.0", "97.0"],
      ["9", "202268.0", "122053.0", "94.0"],
      ["9", "213816.0", "122011.0", "100.0"],
      ["9", "213840.0", "122023.0", "100.0"],
      ["9", "202698.0", "117050.0", "100.0"],
      ["9", "202676.0", "116483.0", "100.0"],
      ["9", "202656.0", "116419.0", "100.0"],
      ["9", "201322.0", "123419.0", "95.0"],
      ["9", "204086.0", "121759.0", "97.0"],
      ["10", "204071.0", "121325.0", "95.0"],
      ["10", "204065.0", "119174.0", "96.0"],
      ["10", "204070.8", "120538.6", "94.0"],
      ["10", "204072.0", "121308.0", "94.0"],
      ["10", "204065.0", "124755.0", "94.0"],
      ["10", "204077.0", "120539.6", "94.0"],
      ["10", "204076.0", "120814.0", "94.0"],
      ["10", "204064.0", "124895.0", "94.0"],
      ["10", "204065.0", "125041.0", "94.0"],
      ["10", "204070.7", "120540.4", "94.0"],
      ["10", "204071.0", "120541.7", "93.0"],
      ["10", "204070.0", "117435.0", "95.0"],
      ["10", "204070.0", "120532.0", "100.0"],
      ["10", "202716.0", "116778.0", "97.0"],
      ["10", "202701.0", "116565.0", "91.0"],
      ["10", "201342.0", "115965.0", "93.0"],
      ["10", "204070.0", "117209.0", "97.0"],
      ["10", "204073.2", "120538.1", "94.0"],
      ["10", "204074.0", "121071.0", "94.0"],
      ["10", "201336.0", "117830.0", "100.0"],
      ["10", "202497.0", "116047.0", "91.0"],
      ["10", "203666.0", "121475.0", "96.0"],
      ["10", "204018.0", "122719.0", "96.0"],
      ["10", "204074.0", "120538.1", "97.0"],
      ["10", "201339.0", "121133.0", "93.0"],
      ["10", "204070.9", "120540.2", "97.0"],
      ["10", "204070.9", "120540.1", "97.0"],
      ["10", "204071.0", "119150.0", "96.0"],
      ["10", "204073.0", "120521.0", "94.0"],
      ["10", "204073.2", "120538.2", "94.0"],
      ["10", "204067.0", "122500.0", "94.0"],
      ["10", "204062.0", "123711.0", "100.0"],
      ["10", "202594.0", "117482.0", "94.0"],
      ["10", "202716.0", "119039.0", "96.0"],
      ["10", "214661.0", "117215.0", "99.0"],
      ["10", "215111.4", "117215.0", "100.0"],
      ["10", "216150.0", "117215.0", "100.0"],
      ["10", "216150.0", "117219.0", "100.0"],
      ["10", "216613.0", "117217.0", "100.0"],
      ["10", "204068.0", "118541.0", "83.0"],
      ["10", "204066.0", "118856.0", "96.0"],
      ["10", "204068.0", "120459.0", "95.0"],
      ["10", "201336.0", "124369.0", "90.0"],
      ["10", "204072.0", "120541.0", "94.0"],
      ["10", "204075.3", "120539.8", "94.0"],
      ["10", "201337.0", "122170.0", "93.0"],
      ["10", "202905.0", "117028.0", "76.0"],
      ["10", "206334.0", "117212.0", "100.0"],
      ["10", "201340.0", "118952.0", "93.0"],
      ["10", "204067.0", "120750.0", "94.0"],
      ["10", "201334.0", "118076.0", "92.0"],
      ["10", "204069.0", "123956.0", "87.0"],
      ["10", "202612.0", "116798.0", "82.0"],
      ["10", "202721.0", "116221.0", "100.0"],
      ["10", "204097.0", "123929.0", "96.0"],
      ["10", "204075.0", "125135.0", "93.0"],
      ["10", "201345.0", "122966.0", "93.0"],
      ["10", "201334.0", "118909.0", "93.0"],
      ["10", "204069.0", "120544.0", "93.0"],
      ["10", "204075.0", "123357.0", "94.0"],
      ["10", "204067.0", "120539.4", "94.0"],
      ["10", "204066.0", "120198.0", "96.0"],
      ["10", "204068.0", "120537.0", "96.0"],
      ["10", "204074.0", "122973.0", "93.0"],
      ["10", "201341.0", "121828.0", "92.0"],
      ["10", "201339.0", "119881.0", "92.0"],
      ["10", "204064.0", "120520.0", "94.0"],
      ["10", "204066.2", "120537.8", "94.0"],
      ["10", "204065.6", "120539.2", "93.0"],
      ["10", "204070.0", "120746.0", "94.0"],
      ["10", "204068.0", "120539.4", "94.0"],
      ["10", "204067.0", "120538.4", "97.0"],
      ["10", "204070.0", "123388.0", "94.0"],
      ["10", "204069.0", "120807.0", "99.0"],
      ["10", "204065.0", "120464.0", "100.0"],
      ["10", "202798.0", "123586.0", "93.0"],
      ["10", "211475.0", "124410.0", "88.0"],
      ["10", "214160.0", "124416.0", "100.0"],
      ["10", "215193.1", "124436.6", "100.0"],
      ["10", "205260.0", "124420.0", "99.0"],
      ["10", "203670.0", "124418.0", "99.0"],
      ["10", "202714.0", "116240.0", "98.0"],
      ["10", "202702.0", "117038.0", "100.0"],
      ["10", "202292.0", "115988.0", "97.0"],
      ["10", "204072.1", "120537.8", "94.0"],
      ["10", "204073.0", "125100.0", "93.0"],
      ["10", "204070.0", "120536.7", "94.0"],
      ["10", "204069.0", "119172.0", "97.0"],
      ["10", "204068.0", "117011.0", "97.0"],
      ["10", "204067.1", "120540.1", "94.0"],
      ["10", "204066.0", "120537.0", "100.0"],
      ["10", "204064.6", "120539.1", "94.0"],
      ["10", "204066.6", "120539.2", "94.0"],
      ["10", "204065.1", "120538.6", "94.0"],
      ["10", "204067.0", "117600.0", "92.0"],
      ["10", "204074.2", "120538.7", "94.0"],
      ["10", "202641.0", "121051.0", "35.0"],
      ["10", "202630.0", "121869.0", "79.0"],
      ["10", "202406.0", "122898.0", "93.0"],
      ["10", "202797.0", "122035.0", "90.0"],
      ["10", "216597.0", "122027.0", "100.0"],
      ["10", "201324.7", "117721.1", "93.0"],
      ["10", "201325.0", "117720.8", "93.0"],
      ["10", "201333.0", "117207.0", "96.0"],
      ["10", "204069.0", "120200.0", "94.0"],
      ["10", "204071.0", "118038.0", "99.0"],
      ["10", "201328.0", "120185.0", "92.0"],
      ["10", "204065.0", "118820.0", "90.0"],
      ["10", "204070.0", "119351.0", "97.0"],
      ["10", "202712.0", "124199.0", "93.0"],
      ["10", "219722.0", "122014.0", "100.0"],
      ["10", "202053.0", "116406.0", "96.0"],
      ["10", "204095.0", "117015.0", "95.0"],
      ["10", "204067.0", "118430.0", "96.0"],
      ["10", "202179.0", "122739.0", "97.0"],
      ["10", "202268.0", "122212.0", "94.0"],
      ["10", "201330.0", "123605.0", "93.0"],
      ["10", "201332.0", "121128.0", "94.0"],
      ["10", "201335.0", "121122.8", "94.0"],
      ["10", "204063.0", "118516.0", "89.0"],
      ["10", "216363.3", "122033.0", "100.0"],
      ["10", "216362.8", "122034.0", "97.0"],
      ["10", "216353.0", "122037.0", "100.0"],
      ["10", "213199.0", "122014.0", "100.0"],
      ["10", "213199.0", "122019.0", "100.0"],
      ["10", "202348.0", "122094.0", "94.0"],
      ["10", "201337.0", "117118.0", "93.0"],
      ["11", "201279.0", "122921.0", "97.0"],
      ["11", "204115.0", "119700.0", "99.0"],
      ["11", "204109.0", "120225.0", "99.0"],
      ["11", "201280.0", "120801.0", "94.0"],
      ["11", "204112.0", "120579.3", "94.0"],
      ["11", "204109.0", "117408.0", "97.0"],
      ["11", "201294.0", "124480.0", "94.0"],
      ["11", "201299.0", "123471.8", "94.0"],
      ["11", "201296.0", "121092.0", "94.0"],
      ["11", "201294.0", "121072.0", "94.0"],
      ["11", "201280.0", "120182.0", "97.0"],
      ["11", "204111.0", "124818.0", "94.0"],
      ["11", "201297.0", "123223.0", "94.0"],
      ["11", "204110.0", "120734.0", "94.0"],
      ["11", "201279.0", "123847.0", "86.0"],
      ["11", "204109.0", "117886.0", "100.0"],
      ["11", "204111.0", "120299.0", "100.0"],
      ["11", "204112.0", "120554.0", "94.0"],
      ["11", "204114.0", "120579.4", "94.0"],
      ["11", "204113.8", "120579.4", "94.0"],
      ["11", "204113.1", "120576.0", "94.0"],
      ["11", "204113.4", "120575.7", "94.0"],
      ["11", "204113.8", "120575.0", "94.0"],
      ["11", "204114.0", "120581.8", "94.0"],
      ["11", "204107.0", "121479.0", "94.0"],
      ["11", "204114.0", "122948.0", "91.0"],
      ["11", "204113.0", "120579.8", "94.0"],
      ["11", "204113.0", "120579.9", "94.0"],
      ["11", "204113.1", "120578.4", "94.0"],
      ["11", "204113.0", "120580.6", "94.0"],
      ["11", "204111.0", "122575.0", "93.0"],
      ["11", "204111.0", "120227.0", "99.0"],
      ["11", "204115.0", "120576.6", "92.0"],
      ["11", "204113.0", "120709.0", "92.0"],
      ["11", "204106.0", "122873.0", "94.0"],
      ["11", "204112.2", "120578.0", "94.0"],
      ["11", "204112.5", "120578.8", "94.0"],
      ["11", "204110.0", "120507.0", "94.0"],
      ["11", "204106.0", "120960.0", "94.0"],
      ["11", "202673.0", "116826.0", "100.0"],
      ["11", "204105.0", "119574.0", "99.0"],
      ["11", "203527.0", "116776.0", "97.0"],
      ["11", "212473.0", "117254.0", "100.0"],
      ["11", "212731.0", "117253.0", "100.0"],
      ["11", "214021.0", "117248.0", "100.0"],
      ["11", "208744.0", "117255.0", "100.0"],
      ["11", "201298.0", "123231.0", "91.0"],
      ["11", "204112.0", "121118.0", "94.0"],
      ["11", "201275.0", "123192.0", "86.0"],
      ["11", "204130.0", "124808.0", "97.0"],
      ["11", "204109.0", "123647.0", "94.0"],
      ["11", "204118.0", "120579.6", "94.0"],
      ["11", "204114.0", "120092.0", "92.0"],
      ["11", "204114.0", "120578.7", "92.0"],
      ["11", "204116.0", "120579.3", "92.0"],
      ["11", "204116.0", "120580.0", "92.0"],
      ["11", "204119.0", "120578.0", "94.0"],
      ["11", "204113.0", "116919.0", "97.0"],
      ["11", "204113.0", "120053.0", "97.0"],
      ["11", "204114.1", "120578.3", "94.0"],
      ["11", "204116.0", "120577.6", "94.0"],
      ["11", "204109.0", "120425.0", "94.0"],
      ["11", "201280.0", "124414.0", "86.0"],
      ["11", "204106.0", "117756.0", "97.0"],
      ["11", "204112.0", "120025.0", "97.0"],
      ["11", "204115.0", "120578.0", "97.0"],
      ["11", "204119.0", "121411.0", "97.0"],
      ["11", "201279.0", "121221.0", "93.0"],
      ["11", "202725.0", "118971.0", "97.0"],
      ["11", "205024.0", "117256.0", "97.0"],
      ["11", "202278.0", "118665.0", "97.0"],
      ["11", "214595.0", "117256.0", "100.0"],
      ["11", "220422.0", "117264.0", "100.0"],
      ["11", "219062.0", "117247.0", "100.0"],
      ["11", "204114.0", "118571.0", "97.0"],
      ["11", "202747.0", "116652.0", "94.0"],
      ["11", "222119.0", "117259.0", "100.0"],
      ["11", "222950.0", "117253.0", "100.0"],
      ["11", "202716.0", "117252.0", "96.0"],
      ["11", "202128.0", "117919.0", "95.0"],
      ["11", "204124.0", "121545.0", "96.0"],
      ["11", "201609.0", "118352.0", "94.0"],
      ["11", "204115.4", "120574.5", "94.0"],
      ["11", "204117.1", "120575.4", "94.0"],
      ["11", "204115.0", "123287.0", "92.0"],
      ["11", "201293.7", "123472.2", "94.0"],
      ["11", "204112.0", "122980.0", "91.0"],
      ["11", "202733.0", "119385.0", "98.0"],
      ["11", "204526.0", "117253.0", "97.0"],
      ["11", "204109.0", "120921.0", "97.0"],
      ["11", "201285.0", "118735.0", "97.0"],
      ["11", "201285.0", "118323.0", "97.0"],
      ["11", "204107.0", "118892.0", "97.0"],
      ["11", "204116.0", "125021.0", "94.0"],
      ["11", "203992.0", "119114.0", "100.0"],
      ["11", "204111.0", "117977.0", "97.0"],
      ["11", "204198.0", "122045.0", "94.0"],
      ["11", "204522.0", "122046.0", "94.0"],
      ["11", "205377.0", "122053.0", "100.0"],
      ["11", "203502.0", "121797.0", "94.0"],
      ["11", "204111.0", "120576.0", "100.0"],
      ["11", "204119.0", "120574.8", "92.0"],
      ["11", "204120.3", "120574.8", "92.0"],
      ["11", "204115.1", "120576.2", "94.0"],
      ["11", "201296.0", "121308.0", "94.0"],
      ["11", "204118.0", "120575.4", "94.0"],
      ["11", "204119.8", "120574.6", "94.0"],
      ["11", "204120.7", "120574.9", "94.0"],
      ["11", "204120.0", "120574.1", "94.0"],
      ["11", "204113.0", "119386.0", "97.0"],
      ["11", "201285.0", "118802.0", "94.0"],
      ["11", "204113.0", "119743.0", "100.0"],
      ["11", "204115.8", "120578.5", "94.0"],
      ["11", "204117.0", "120574.6", "94.0"],
      ["11", "204117.2", "120575.6", "94.0"],
      ["11", "204117.8", "120575.1", "94.0"],
      ["11", "201288.0", "116985.0", "94.0"],
      ["11", "204105.0", "117878.0", "97.0"],
      ["11", "204115.0", "119011.0", "97.0"],
      ["11", "204127.0", "116232.0", "97.0"],
      ["11", "201283.0", "116995.0", "94.0"],
      ["11", "204115.0", "124819.0", "94.0"],
      ["11", "204114.4", "120576.5", "94.0"],
      ["11", "204115.0", "120576.3", "94.0"],
      ["11", "204117.7", "120577.5", "94.0"],
      ["11", "204113.0", "120346.0", "97.0"],
      ["11", "201282.0", "121051.0", "93.0"],
      ["11", "204125.0", "122382.0", "97.0"],
      ["11", "204112.0", "121742.0", "92.0"],
      ["11", "201276.0", "118810.0", "94.0"],
      ["11", "204109.1", "120575.2", "94.0"],
      ["11", "204108.0", "120923.0", "94.0"],
      ["11", "201279.0", "124231.0", "93.0"],
      ["11", "204105.0", "120567.0", "94.0"],
      ["11", "201296.0", "123475.0", "94.0"],
      ["11", "201295.0", "123472.9", "94.0"],
      ["11", "171673.0", "124457.0", "82.0"],
      ["11", "202676.0", "120406.0", "94.0"],
      ["11", "201278.0", "118233.0", "93.0"],
      ["11", "201299.0", "122327.0", "93.0"],
      ["11", "204107.8", "120573.8", "94.0"],
      ["11", "204107.0", "120572.4", "94.0"],
      ["11", "204109.0", "120512.0", "97.0"],
      ["11", "204107.0", "120574.5", "94.0"],
      ["11", "201293.0", "123418.0", "94.0"],
      ["11", "204115.0", "118397.0", "100.0"],
      ["11", "204111.0", "120410.0", "100.0"],
      ["11", "201291.0", "116406.0", "99.0"],
      ["11", "204126.0", "122663.0", "100.0"],
      ["11", "204109.0", "117172.0", "97.0"],
      ["11", "201293.0", "121112.0", "92.0"],
      ["11", "201293.0", "121071.8", "94.0"],
      ["11", "201287.0", "118399.0", "97.0"],
      ["11", "201274.0", "122344.0", "93.0"],
      ["11", "202667.0", "116645.0", "97.0"],
      ["11", "202846.0", "118770.0", "100.0"],
      ["11", "203138.0", "119925.0", "100.0"],
      ["11", "204108.0", "118278.0", "97.0"],
      ["11", "204124.0", "124951.0", "92.0"],
      ["11", "201278.0", "116077.0", "100.0"],
      ["11", "204104.0", "120575.8", "94.0"],
      ["11", "204107.3", "120572.2", "94.0"],
      ["11", "204104.0", "117593.0", "97.0"],
      ["11", "201274.0", "119482.0", "93.0"],
      ["11", "204116.0", "117824.0", "95.0"],
      ["11", "201292.0", "118808.0", "94.0"],
      ["11", "202734.0", "118896.0", "100.0"],
      ["11", "205615.0", "117243.0", "97.0"],
      ["11", "207578.0", "117248.0", "100.0"],
      ["11", "202390.0", "116120.0", "97.0"],
      ["11", "204103.0", "120194.0", "97.0"],
      ["11", "202260.0", "116202.0", "97.0"],
      ["11", "204849.6", "117244.4", "97.0"],
      ["11", "206290.7", "117236.0", "97.0"],
      ["11", "205907.0", "117247.0", "97.0"],
      ["11", "204275.0", "122046.0", "94.0"],
      ["11", "213794.0", "122047.5", "100.0"],
      ["11", "213983.0", "122045.0", "100.0"],
      ["11", "204112.0", "123989.0", "94.0"],
      ["11", "201278.0", "122095.0", "94.0"],
      ["11", "202252.0", "122243.0", "91.0"],
      ["11", "202994.0", "122060.0", "91.0"],
      ["11", "210631.0", "122053.0", "100.0"],
      ["11", "201284.0", "121212.0", "86.0"],
      ["11", "201278.0", "124222.0", "86.0"],
      ["11", "201282.0", "122539.0", "94.0"],
      ["11", "201292.0", "120049.0", "94.0"],
      ["11", "201293.0", "118922.0", "94.0"],
      ["11", "204108.0", "120247.0", "100.0"],
      ["11", "202852.0", "121130.0", "84.0"],
      ["11", "202735.0", "124144.0", "93.0"],
      ["11", "204709.0", "122048.0", "94.0"],
      ["11", "203289.0", "122053.0", "98.0"],
      ["11", "204109.0", "120470.0", "100.0"],
      ["11", "204106.6", "120574.3", "94.0"],
      ["11", "204106.0", "120588.0", "94.0"],
      ["11", "204106.7", "120576.4", "94.0"],
      ["11", "201282.0", "119624.0", "97.0"],
      ["11", "204108.0", "120937.0", "94.0"],
      ["11", "204106.0", "122582.0", "94.0"],
      ["11", "204110.0", "120064.0", "97.0"],
      ["11", "204105.0", "120576.9", "94.0"],
      ["11", "204104.3", "120576.6", "94.0"],
      ["11", "204104.0", "120577.3", "94.0"],
      ["11", "204114.0", "121936.0", "91.0"],
      ["11", "204113.0", "116809.0", "100.0"],
      ["11", "204109.0", "116741.0", "97.0"],
      ["11", "204117.0", "121780.0", "100.0"],
      ["11", "204119.0", "124449.0", "93.0"],
      ["11", "222630.0", "124450.0", "100.0"],
      ["11", "223024.0", "124451.0", "100.0"],
      ["11", "202055.0", "123986.0", "94.0"],
      ["11", "204112.0", "121256.0", "100.0"],
      ["11", "204114.0", "119124.0", "97.0"],
      ["11", "202226.0", "115992.0", "96.0"],
      ["11", "201297.0", "124092.0", "94.0"],
      ["11", "201278.0", "116855.0", "97.0"],
      ["11", "203446.0", "123551.0", "91.0"],
      ["11", "202313.0", "124463.0", "93.0"],
      ["11", "213016.0", "124457.0", "100.0"],
      ["11", "214497.0", "124451.0", "100.0"],
      ["11", "202201.0", "123422.0", "88.0"],
      ["11", "202424.0", "120843.0", "94.0"],
      ["11", "204109.0", "122464.0", "97.0"],
      ["11", "204111.0", "124159.0", "94.0"],
      ["11", "204119.0", "124154.0", "97.0"],
      ["11", "201294.0", "118711.0", "97.0"],
      ["11", "201296.0", "118416.0", "97.0"],
      ["11", "213797.0", "122060.0", "100.0"],
      ["11", "214713.0", "122060.0", "100.0"],
      ["11", "204104.0", "118029.0", "97.0"],
      ["11", "201287.0", "116238.0", "97.0"],
      ["11", "204106.2", "120575.8", "94.0"],
      ["11", "203337.0", "121179.0", "99.0"],
      ["11", "206909.0", "122058.0", "94.0"],
      ["11", "207460.0", "122054.0", "100.0"],
      ["11", "202473.0", "124462.0", "92.0"],
      ["11", "217771.6", "124453.1", "100.0"],
      ["11", "207050.0", "124450.0", "100.0"],
      ["11", "207744.0", "122059.0", "100.0"],
      ["11", "204110.0", "118902.0", "97.0"],
      ["11", "204128.0", "121778.0", "94.0"],
      ["11", "201282.0", "119597.0", "94.0"],
      ["11", "201282.0", "117735.0", "94.0"],
      ["11", "201296.0", "124479.0", "94.0"],
      ["11", "204106.0", "116515.0", "97.0"],
      ["11", "201278.0", "123415.0", "86.0"],
      ["11", "204107.0", "122289.0", "94.0"],
      ["11", "201276.0", "116136.0", "94.0"],
      ["11", "204109.0", "118000.0", "97.0"],
      ["11", "204107.4", "120575.4", "94.0"],
      ["11", "204104.2", "120573.4", "94.0"],
      ["11", "201290.0", "121081.0", "97.0"],
      ["11", "201276.0", "118456.0", "94.0"],
      ["11", "202665.0", "116428.0", "94.0"],
      ["11", "204280.0", "122055.0", "100.0"],
      ["11", "204117.0", "124603.0", "94.0"],
      ["11", "211254.0", "122060.0", "100.0"],
      ["11", "207434.0", "122055.0", "99.0"],
      ["11", "201285.0", "124353.0", "86.0"],
      ["11", "202740.0", "116915.0", "100.0"],
      ["11", "201281.0", "116511.0", "94.0"],
      ["11", "201274.0", "117319.0", "92.0"],
      ["12", "204078.0", "116577.0", "97.0"],
      ["12", "201330.0", "124222.0", "93.0"],
      ["12", "204081.0", "120582.0", "97.0"],
      ["12", "201325.0", "116393.0", "93.0"],
      ["12", "204078.0", "120592.0", "92.0"],
      ["12", "204079.0", "120582.2", "97.0"],
      ["12", "204080.0", "120585.0", "97.0"],
      ["12", "204069.0", "122250.0", "94.0"],
      ["12", "201330.0", "118003.0", "93.0"],
      ["12", "204077.0", "120044.0", "96.0"],
      ["12", "201324.8", "118667.9", "93.0"],
      ["12", "204082.0", "120585.8", "97.0"],
      ["12", "204080.0", "122703.0", "96.0"],
      ["12", "201335.0", "118603.0", "93.0"],
      ["12", "204071.0", "116105.0", "96.0"],
      ["12", "204080.0", "120583.2", "92.0"],
      ["12", "204081.7", "120583.7", "94.0"],
      ["12", "204081.0", "120819.0", "94.0"],
      ["12", "204085.9", "120585.0", "97.0"],
      ["12", "201329.0", "123494.0", "92.0"],
      ["12", "201330.0", "118768.0", "91.0"],
      ["12", "204083.0", "120583.9", "97.0"],
      ["12", "204083.0", "120584.6", "97.0"],
      ["12", "204083.9", "120584.8", "97.0"],
      ["12", "204085.0", "120582.3", "94.0"],
      ["12", "201327.0", "123008.0", "90.0"],
      ["12", "201328.0", "121639.0", "90.0"],
      ["12", "201325.0", "123198.0", "93.0"],
      ["12", "204083.1", "120583.3", "97.0"],
      ["12", "204080.0", "120668.0", "97.0"],
      ["12", "204077.0", "120587.0", "94.0"],
      ["12", "204077.0", "119756.0", "95.0"],
      ["12", "204082.0", "120589.0", "97.0"],
      ["12", "204084.5", "120585.0", "97.0"],
      ["12", "202524.0", "118565.1", "97.0"],
      ["12", "202524.3", "118564.6", "97.0"],
      ["12", "202524.9", "118565.2", "97.0"],
      ["12", "203439.0", "117236.0", "99.0"],
      ["12", "216427.0", "117220.0", "100.0"],
      ["12", "217876.0", "117220.0", "100.0"],
      ["12", "204076.0", "123285.0", "94.0"],
      ["12", "204077.0", "124772.0", "94.0"],
      ["12", "212570.0", "117215.0", "100.0"],
      ["12", "204080.0", "121211.0", "97.0"],
      ["12", "201327.0", "116196.0", "90.0"],
      ["12", "201326.0", "122120.0", "92.0"],
      ["12", "202535.0", "118303.0", "97.0"],
      ["12", "204076.0", "117188.0", "97.0"],
      ["12", "201332.0", "119293.0", "96.0"],
      ["12", "204086.0", "120585.3", "94.0"],
      ["12", "204086.0", "120583.1", "97.0"],
      ["12", "204088.9", "120582.8", "97.0"],
      ["12", "204065.0", "123819.0", "97.0"],
      ["12", "204085.7", "120583.8", "97.0"],
      ["12", "204085.5", "120582.3", "97.0"],
      ["12", "201326.5", "123462.9", "92.0"],
      ["12", "201326.0", "123415.0", "92.0"],
      ["12", "201327.0", "119594.0", "94.0"],
      ["12", "204086.7", "120582.4", "97.0"],
      ["12", "201326.0", "122601.0", "84.0"],
      ["12", "201325.8", "121062.7", "93.0"],
      ["12", "201870.0", "119763.0", "96.0"],
      ["12", "217802.0", "117223.0", "100.0"],
      ["12", "217326.0", "117223.0", "100.0"],
      ["12", "214257.0", "117220.0", "96.0"],
      ["12", "204072.0", "124356.0", "97.0"],
      ["12", "202717.0", "117314.0", "97.0"],
      ["12", "202257.0", "118142.0", "97.0"],
      ["12", "209896.0", "117224.0", "100.0"],
      ["12", "209975.0", "117226.0", "100.0"],
      ["12", "206140.0", "117223.0", "100.0"],
      ["12", "201334.0", "116200.0", "100.0"],
      ["12", "201336.0", "120252.0", "93.0"],
      ["12", "204084.5", "120583.1", "97.0"],
      ["12", "204070.0", "115985.0", "96.0"],
      ["12", "201328.0", "118085.0", "96.0"],
      ["12", "201325.0", "116260.0", "93.0"],
      ["12", "204071.0", "121373.0", "94.0"],
      ["12", "204074.0", "125006.0", "94.0"],
      ["12", "204080.5", "120581.7", "94.0"],
      ["12", "201333.0", "124278.0", "85.0"],
      ["12", "201327.0", "121108.0", "89.0"],
    ],
  },
};
