// Convert the array into a JSON object with the timestamp as the key
export const groupLgvsByTimeFrame = (dataArr) => {
  return dataArr.reduce(
    (acc, { timestamp, vehicleNumber, longitude, latitude, status }) => {
      // Check if timestamp already exists in accumulator object
      if (!acc[timestamp]) {
        acc[timestamp] = [];
      }

      // Check if vehicleNumber is already in the array for this timestamp
      const vehicleExists = acc[timestamp].some(
        (entry) => entry.vehicleNumber === vehicleNumber
      );

      // If the vehicle doesn't exist in the array, add it
      if (!vehicleExists) {
        acc[timestamp].push({
          vehicleNumber,
          longitude,
          latitude,
          status,
        });
      }

      return acc;
    },
    {}
  );
};

export const groupDockByTimeFrame = (dataArr) => {
  return dataArr.reduce(
    (
      acc,
      { timestamp, DOCK, POSITION, SKU, LONGITUDE, LATITUDE, STATUS, STATE }
    ) => {
      if (!acc[timestamp]) {
        acc[timestamp] = [];
      }
      acc[timestamp].push({
        DOCK,
        longitude: LONGITUDE,
        latitude: LATITUDE,
        STATUS: STATUS || "",
        POSITION,
        SKU,
        STATE,
      });
      return acc;
    },
    {}
  );
};

export const groupBlockByTimeFrame = (dataArr) => {
  return dataArr.reduce(
    (
      acc,
      {
        timestamp,
        location,
        totalPallets,
        storageDisabled,
        retrievalDisabled,
        positionRetrievalDisabled,
        positionRetrievalBlocked,
        locationType,
        totalPositions,
        positionTransition,
        positionOrientation,
        doubleRows,
        pbcRows,
        longitude,
        latitude,
        positionLength,
        angle,
        locationOrientation,
        palletPosition,
        row,
        palletLevel,
        lpn,
        sku,
      }
    ) => {
      if (!acc[timestamp]) {
        acc[timestamp] = [];
      }
      acc[timestamp].push({
        timestamp,
        location,
        totalPallets,
        storageDisabled,
        retrievalDisabled,
        positionRetrievalDisabled,
        positionRetrievalBlocked,
        locationType,
        totalPositions,
        positionTransition,
        positionOrientation,
        doubleRows,
        pbcRows,
        longitude,
        latitude,
        positionLength,
        angle,
        locationOrientation,
        palletPosition,
        row,
        palletLevel,
        lpn,
        sku,
      });
      return acc;
    },
    {}
  );
};

export const groupRackByTimeFrame = (dataArr) => {
  return dataArr.reduce(
    (
      acc,
      {
        timestamp,
        location,
        totalPallets,
        storageDisabled,
        retrievalDisabled,
        positionRetrievalDisabled,
        positionRetrievalBlocked,
        locationType,
        totalPositions,
        longitude,
        latitude,
        positionLength,
        angle,
        locationOrientation,
        palletPosition,
        row,
        palletLevel,
        lpn,
        sku,
        rackType,
      }
    ) => {
      if (!acc[timestamp]) {
        acc[timestamp] = [];
      }
      acc[timestamp].push({
        location,
        totalPallets,
        storageDisabled,
        retrievalDisabled,
        positionRetrievalDisabled,
        positionRetrievalBlocked,
        locationType,
        totalPositions,
        longitude,
        latitude,
        positionLength,
        angle,
        locationOrientation,
        palletPosition,
        row,
        palletLevel,
        lpn,
        sku,
        rackType,
      });
      return acc;
    },
    {}
  );
};

export const groupProductinLineByTimeFrame = (dataArr) => {
  let i = 1;
  return dataArr.reduce(
    (
      acc,
      {
        timestamp,
        LINE_NAME,
        LONGITUDE,
        LATITUDE,
        LGV,
        PALLETS,
        STATION_1,
        STATION_2,
      }
    ) => {
      if (!acc[timestamp]) {
        acc[timestamp] = [];
      }

      // Check if LINE_NAME is already in the array for this timestamp
      const lineExist = acc[timestamp].some(
        (entry) => entry.LINE_NAME === LINE_NAME
      );

      if (!lineExist) {
        acc[timestamp].push({
          LINE_NAME: LINE_NAME || "L" + i,
          longitude: LONGITUDE,
          latitude: LATITUDE,
          LGV,
          PALLETS,
          STATION_1,
          STATION_2,
        });
        i++;
      }

      return acc;
    },
    {}
  );
};

// Function to generate time frames
export const generateTimeFrame = (startTimeString, endTimeString) => {
  let endDtObj = new Date(endTimeString);
  let startDtObj = new Date(startTimeString);
  let timeFrame = [];

  while (startDtObj < endDtObj) {
    timeFrame.push(startDtObj.toISOString());
    startDtObj.setSeconds(startDtObj.getSeconds() + 1);
  }

  return timeFrame;
};

export const getTimeInISOFromZero = (timeObj) => {
  if (timeObj instanceof Date && !isNaN(timeObj)) {
    let fromTimeObj = new Date(timeObj);
    fromTimeObj.setSeconds(0);
    let endTimeObj = new Date(timeObj);
    endTimeObj.setSeconds(0);
    endTimeObj.setMinutes(endTimeObj.getMinutes()+1)

    return {
      startTime: fromTimeObj.toISOString(),
      endTime: endTimeObj.toISOString(),
    };
  } else {
    return null;
  }
};
