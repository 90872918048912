import "./zoomIndicator.css";
import info from "../../../../assets/icons/info-circle.svg";
const ZoomIndicator = ({ zoomLevel }) => {
  return (
    <div className="zoom-indicator-wrapper">
      <div className="zoom-indicator-container">
        <div className="zoom-indicator-container-left-section">
          <div className="info-icon-left-section">
            <img alt="infoButton" src={info} />
          </div>
          <div className="info-text-left-section">Zoom:</div>
        </div>
        <div className="zoom-indicator-container-right-section">
          <div className="zoom-text-right-section">Level {zoomLevel}</div>
        </div>
      </div>
    </div>
  );
};

export default ZoomIndicator;
